import React, { useEffect, useState } from 'react';
import { Input, Select } from '../../components';
import { axios, Constants, Globals } from '../../utils';
import LeftArrowIcon from '../../assets/icons/left-arrow.png';

const PaymentSyspago = ({ onGoBack, onClose, amount, methodInfo, conversion, plan, user }) => {
  const [banks, setBanks] = useState([]);
  const [form, setForm] = useState({
    amount,
    amount_bs: parseFloat(parseFloat(amount * conversion).toFixed(2)),
    reference_number: '',
    bank_id: '',
    phone: '',
    phone_code: '',
    document: '',
    document_type: '',
    date: '',
    otp: '',
    showOtpInput: false,
  });

  const getBanks = async () => {
    try {
      const response = await axios.post('admin/plans/syspago/banksotp');
      setBanks(response.data.banks.map(x => ({ value: x.id, label: x.name })));

    } catch (error) {
      Globals.showError('Error al cargar los bancos');
    }
  }

  const onChange = async (value, target) => {
    setForm(state => ({ ...state, [target]: value }));
  }

  const validateForm = (e) => {
    e.preventDefault();

    if (!form.bank_id)
      return Globals.showError('Debe seleccionar el banco');

    if (!form.document)
      return Globals.showError('Debe ingresar el número de documento');

    if (!form.document_type)
      return Globals.showError('Debe seleccionar el tipo de documento');

    if (!form.phone)
      return Globals.showError('Debe ingresar el número de teléfono');

    if (!form.phone_code)
      return Globals.showError('Debe seleccionar el código de teléfono');

    if (form.showOtpInput && !form.otp)
      return Globals.showError('Debe ingresar el código OTP');

    submit();
  }

  const submit = async () => {
    const params = {
      user_id: user.id,
      year: plan?.payment?.year ?? 0,
      bank_id: form.bank_id,
      plan_id: plan?.id,
      payment_method_id: Constants.PLANS.PAYMENT_METHODS.SYSPAGO,
      otp: form.otp,
      phone_code: form.phone_code,
      phone: '0' + form.phone_code + form.phone,
      reference_number: form.reference_number,
      total: form.amount_bs,
      global_conversion: conversion,
      date: '',
      document: form.document_type + form.document
      //Datos BNCVPOS
      // AccountType: form.AccountType,
      // CardHolderID: form.CardHolderID,
      // CardNumber: form.CardNumber,
      // CardPIN: form.CardPIN,
      // CVV: form.CVV,
      // dtExpiration: form.dtExpiration,
      // idCardType: form.idCardType
    };

    Globals.setLoading();
    axios.post('admin/plans/change', params)
      .then((res) => {
        if (res.status === 200 && res.data.result && res.data.message === "Tu banco se comunicará contigo para enviarte la clave OTP") {
          onChange(true, 'showOtpInput');
          Globals.showInformation(res.data.message);

        } else if (res.status === 422) {
          const errorMessage = res.data.message || 'Error al renovar el plan';
          Globals.showError(errorMessage);

        } else if (res.status === 200 && res.data.result && res.data.message === "Pago por VPOS realizado exitosamente") {
          const message = res.data.message+' Nro de referencia: '+res.data.reference
          Globals.showSuccess(message);
          onClose();

        } else {
          Globals.showSuccess("Plan renovado correctamente");
          onClose();
        }
      })
      .catch((err) => {
        let message = err.response?.data?.message || err.response?.data?.error || 'Ocurrió un error al enviar el formulario'
        Globals.showError(message);
      })
      .finally(() => {
        Globals.quitLoading();
      });
  }

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <section>
      <div className="back-button" onClick={onGoBack}>
        <img src={LeftArrowIcon} alt="Go Back" />
        <p className="hightlighted">DÉBITO INMEDIATO</p>
      </div>

      <div className="summary">
        <p>Total a pagar</p>
        <p>{ Globals.formatMiles(form.amount_bs) } Bs</p>
      </div>

      <form onSubmit={validateForm}>
        <Select
        label="Banco"
        placeholder="Seleccionar Banco"
        options={banks}
        value={form.bank_id}
        onChange={e => onChange(e.target.value, 'bank_id')}
        />

        <div className="phone-input">
          <Select
            label="Teléfono"
            placeholder="Código"
            options={Constants.PHONE_CODES}
            value={form.phone_code}
            onChange={e => onChange(e.target.value, 'phone_code')}
          />
          <Input
            label=" "
            placeholder="Número"
            value={form.phone}
            onChange={e => onChange(e.target.value, 'phone')}
          />
        </div>

        <div className="phone-input">
          <Select
            label="Cédula/RIF"
            placeholder="Tipo"
            options={Constants.TYPE_DOCUMENTS}
            value={form.document_type}
            onChange={e => onChange(e.target.value, 'document_type')}
          />
          <Input
            label=" "
            placeholder="Número"
            value={form.document}
            onChange={e => onChange(e.target.value, 'document')}
          />
        </div>

        {form.showOtpInput && (
          <Input
            label="Clave OTP"
            placeholder="Ingrese la clave OTP"
            value={form.otp}
            onChange={e => onChange(e.target.value, 'otp')}
          />
        )}

        <button className="save-btn">VALIDAR PAGO</button>
      </form>
    </section>
  )
}

export default PaymentSyspago;