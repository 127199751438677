/* eslint-disable import/no-anonymous-default-export */
const CONFIG = {
  YES: 1,
  NO: 0
};
const COUNTRIES = {
  OTHER: 1,
  VENEZUELA: 2,
  COLOMBIA: 3,
  ECUADOR: 4,
  PANAMA: 5,
  PERU: 6,
  MEXICO: 7,
  CUBA: 8,
};

const STATUS_ACTIVE = 1;
const STATUS_INACTIVE = 0;

const STATUS_INV_COMMITTED = 0;
const STATUS_INV_PROCESSED = 1;

const STATUS_PAYMENT_INCOME = 1;
const STATUS_PAYMENT_EXPENSES = 2;

const STATE_SUSPENDED = 2;
const STATE_PROCESSED = 1;

const ACTION_CLASS_ENTRY = 1;
const ACTION_CLASS_OUTPUT = 2;

const EXEMPT_ACTIVE = 0;
const EXEMPT_INACTIVE = 1;

const PURCHASE_BILL = 2;
const PURCHASE_ORDER = 1;

const STEP_SELECTION = 1;
const STEP_FORM = 2;

const STEP_SELLER_DATA = 1;
const STEP_SELLER_CONF = 2;

const TYPE_ENTRY = 1;
const TYPE_OUTPUT = 2;

const TYPE_DELIVERY = 1;
const TYPE_BILL = 2;

const TYPE_PAYMENTS_TRANSFER = 4;

const TYPE_PRODUCT = 1;
const TYPE_SALE = 2;

const VALUE_ZERO = 0;

const ROLE_ADMIN = 1;
const ROLE_MODERATOR = 2;
const ROLE_SELLER = 3;
const ROLE_MANAGER = 4;
//const ROLE_SELLER = 5;

const STATUS_RESPONSE_SERVER = 500;
const STATUS_RESPONSE_UNAUTHORIZED = 401;

const PAYMENT_TYPES = {
  PAID: 1,
  TO_PAY: 2
};

const ORDER_STATUS = {
  PENDING: 0,
  PROCESSED: 1,
  REJECTED: 2,
  CANCELED: 5,
  DISPATCHED: 6,
  TO_PAY_STATUS: 3,
  PAID_STATUS: 4,
  DELETED: 'deleted_at',
  PENDING_LABEL: 'En proceso',
  PROCESSED_LABEL: 'Procesado',
  REJECTED_LABEL: 'Rechazado',
  CANCELED_LABEL: 'Anulado',
  DISPATCHED_LABEL: 'Despachado',
}

const TYPE_DOCUMENTS = [
  { value: "V", label: "V" },
  { value: "E", label: "E" },
  { value: "J", label: "J" }
];

const TYPE_IDENTITY = [
  { value: "V", label: "V" },
  { value: "E", label: "E" },
  { value: "J", label: "J" }
];

const TYPE_PERSON = [
  {
    value: 1,
    label: "Natural"
  },
  {
    value: 2,
    label: "Jurídica"
  }
];

const TYPE_ADJUSTMENT = [
  {
    value: 1,
    label: "Ingreso"
  },
  {
    value: 2,
    label: "Salida"
  },
  {
    value: 3,
    label: "Traslado"
  }
];

const TYPE_ADJUSTMENT_LIMITED = [
  {
    value: 1,
    label: "Ingreso"
  },
  {
    value: 2,
    label: "Salida"
  }
];

const SERIALIZATION = {
  ACTIVE: 1,
  INACTIVE: 0
};

const DISCOUNT_TYPES = {
  byProduct: 1,
  bySale: 2 
}

const SELLER_MODULES = {
  SALE_POINT: 22
}

const TYPE_REPORT_CHARGES_STATUS = [
  {
    value: 0,
    label: 'Sin Usar'
  },
  {
    value: 1,
    label: 'Usado'
  }
]

const TYPE_BANKS = [
  {
    value: 1,
    label: 'Bancaria'
  },
  {
    value: 2,
    label: 'Solo Efectivo'
  }
]

const TYPE_BANK_ACCOUNTS = [
  {
    value: 1,
    label: 'Corriente'
  },
  {
    value: 2,
    label: 'Ahorro'
  },
  {
    value: 3,
    label: 'Caja'
  }
]

const EXPENSES_INGRESS_PAYMENT_TYPE_LIST = {
  CASH: 1,
  CREDIT: 2
}

const EXPENSES_INGRESS_METHOD_TYPE_LIST = {
  CASH: 1,
  CREDIT: 2,
  DEBIT: 3,
  TRANSFER: 4
}

const TYPE_BANKS_LIST = {
  REGULAR: 1,
  CASH_ONLY: 2
}

const TYPE_BANK_ACCOUNTS_LIST = {
  CHECKING: 1,
  SAVINGS: 2,
  CASH_ONLY: 3
}

const SALE_PAYMENT_TYPES = {
  CASH: 1,
  CCARD: 2,
  DEBIT: 3,
  TRANSFER: 4,
  CREDIT: 5
}

const SALE_PAYMENT_PROCESSED_LIST = {
  NOT_PROCESSED: 0,
  PROCESSED: 1
}

const PAYMENT_METHODS = {
  CASH_BS: 1,
  ZELLE: 2,
  MOBILE: 3,
  TRANSFER: 4,
  PAYPAL: 5,
  BANESCO: 6,
  CASH_FE: 7,
  BINANCE: 8,
  POS_BS: 9,
  BANCO_EXTERIOR_DIVISAS: 12,
  BANCO_EXTERIOR_ALMACENAMIENTO: 13,
	BANCO_EXTERIOR_BS: 14,
	TRANSFERENCIA_INTERNACIONAL: 15,
  PAGO_MOVIL_BANESCO: 24,
	PAGO_MOVIL_MERCANTIL: 25,
	TRANSFERENCIA_MERCANTIL: 26,
	TRANSFERENCIA_BANESCO: 27,
  TRANSFERENCIA_PROVINCIAL: 28,
  PAGO_MOVIL_BANCAMIGA: 29,
  TRANSFERENCIA_BANCAMIGA: 30,
  PAGO_MOVIL_TESORO: 31,
  TRANSFERENCIA_TESORO: 32,
  TRANSFERENCIA_BINCENTENARIO: 33,
  PAGO_MOVIL_VENEZUELA: 34,
  TRANSFERENCIA_VENEZUELA: 35,
  MERCANTIL_PANAMA: 36,
  //CUBA
  EFECTIVO_CUP: 16,
  EFECTIVO_USD_CUP: 17,
  EFECTIVO_EUR_CUP: 18,
  EFECTIVO_CAD_CUP: 19,
  MLC_CUP: 20,
  TRANFERMOVIL_CUP: 21,
  USD_PANAMA_CUP: 22,
  USD_ZELLE_OR_TRANSF_CUP: 23,
  //PERU
  TRANSF_BANCO_COMERCIO: 37,
  TRANSF_BANCO_CREDITO_PERU: 38,
  TRANSF_BANBIF: 39,
  TRANSF_BANCO_PICHINCHA: 40,
  TRANSF_BBVA: 41,
  TRANSF_CITYBANK_PERU: 42,
  TRANSF_INTERBANK_PERU: 43,
  TRANSF_MIBANCO_PERU: 44,
  SCOTIABANK_PERU: 45,
  GNB_PERU: 46,
  FALABELLA_PERU: 47,
  RIPLEY_PERU: 48,
  SANTANDER_PERU: 49,
  CULQI_PERU: 50,
  PAYPAL_PERU: 51,
  MERCADOPAGO_PERU: 52,
  NIUBIZ_PERU: 53,
  IZIPAY_PERU: 54,
  //ECUADOR
  TRANSF_GRUPO_PICHINCHA_ECU: 55,
  TRANSF_BCO_PICHINCHA_ECU: 56,
  TRANSF_PRODUBANCO_ECU: 57,
  TRANSF_BCO_PACIFICO_ECU: 58,
  TRANSF_BCO_GUAYAQUIL_ECU: 59,
  TRANSF_BCO_INTERNA_ECU: 60,
  TRANSF_BCO_BOLIVARIANO_ECU: 61,
  TRANSF_BCO_AUSTRO_ECU: 62,
  TRANSF_BCO_DESARROLLO_DEL_ECUADOR: 63,
  TRANSF_BCO_GENERAL_RUMINAHUI_ECU: 64,
  TRANSF_BCO_MACHALA_ECU: 65,
  TRANSF_BCO_SOLIDARIO_ECU: 66,
  TRANSF_BANECUADOR_ECU: 67,
  TRANSF_BCO_LOJA_ECU: 68,
  TRANSF_CITIBANK_ECU: 69,
  TRANSF_PROCREDT_ECU: 70,
  TRANSF_AMAZONAS_ECU: 71,
  TRANSF_BCO_DESARROLLO_ECU: 72,
  TRANSF_COOPNACIONAL_ECU: 73,
  TRANSF_VISIONFUND_ECU: 74,
  TRANSF_D_MIRO_ECU: 75,
  TRANSF_AMIBANK_ECU: 76,
  TRANSF_COMERCIAL_MANABI_ECU: 77,
  TRANSF_CAPITAL_ECU: 78,
  TRANSF_BIESS_ECU: 79,
  TRANSF_BCO_LITORAL_ECU: 80,
  TRANSF_DELBANK_ECU: 81,
  //COLOMBIA
  RAPI_PAGO_COL: 82,
  PAYCO_COL: 83,
  PAGO_ONLINE_COL: 84,
  EFECTY_COL: 85,
  MERCADO_PAGO_COL: 86,
  SAFETY_PAY_COL: 87,
  PAY_U_COL: 88,
  PAYPAL_COL: 89,
  TRANSF_BANCO_AGRA_COL: 90,
  TRANSF_BANCO_AVVILLAS_COL: 91,
  TRANSF_BANCO_CAJASOCIAL_COL: 92,
  TRANSF_BANCO_OCCIDENTE_COL: 93,
  TRANSF_BANCO_GRANAHORRAR_COL: 94,
  TRANSF_BANCO_ITAU_COL: 95,
  TRANSF_BANCO_PICHINCHA_COL: 96,
  TRANSF_BANCO_POPULAR_COL: 97,

  // ARGENTINA
  BANCO_NAC_ARGENTINA: 99,
  BANCO_PROV_BNOS_AIRES: 100,
  BANCO_GALICIA_BNOS_AIRES: 101,
  BANCO_SANTANDER_ARGENTINA: 102,
  BBVA_ARGENTINA: 103,
  BANCO_PATAGONIA: 104,
  HSBC_ARGENTINA: 105,
  BANCO_SUPERVIELLE: 106,
  BANCO_HIPOTECARIO: 107,
  BANCO_COMAFI: 108,
  BANCO_BICA: 109,
  BANCO_COLUMBIA: 110,
  BANCO_DEL_CHUBUT: 111,
  BANCO_ENTRE_RIOS: 112,
  BANCO_SAN_JUAN: 113,
  BANCO_SANTIAGO_DEL_ESTERO: 114,
  BANCO_TUCUMAN: 115,
  BRUBANK: 116,
}

const LOCAL_CURRENCIES = [
  PAYMENT_METHODS.CASH_BS,
  PAYMENT_METHODS.TRANSFER,
  PAYMENT_METHODS.MOBILE,
  PAYMENT_METHODS.POS_BS,
  PAYMENT_METHODS.BANCO_EXTERIOR_BS,
  PAYMENT_METHODS.PAGO_MOVIL_BANESCO,
	PAYMENT_METHODS.PAGO_MOVIL_MERCANTIL,
	PAYMENT_METHODS.TRANSFERENCIA_MERCANTIL,
	PAYMENT_METHODS.TRANSFERENCIA_BANESCO,
  PAYMENT_METHODS.TRANSFERENCIA_PROVINCIAL,
  PAYMENT_METHODS.PAGO_MOVIL_BANCAMIGA,
  PAYMENT_METHODS.TRANSFERENCIA_BANCAMIGA,
  PAYMENT_METHODS.PAGO_MOVIL_TESORO,
  PAYMENT_METHODS.TRANSFERENCIA_TESORO,
  PAYMENT_METHODS.TRANSFERENCIA_BINCENTENARIO,
  PAYMENT_METHODS.PAGO_MOVIL_VENEZUELA,
  PAYMENT_METHODS.TRANSFERENCIA_VENEZUELA,
  //CUBA
  PAYMENT_METHODS.EFECTIVO_CUP,
  PAYMENT_METHODS.MLC_CUP,
  PAYMENT_METHODS.TRANFERMOVIL_CUP,
  // PERU
  PAYMENT_METHODS.TRANSF_BANCO_COMERCIO,
  PAYMENT_METHODS.TRANSF_BANCO_CREDITO_PERU,
  PAYMENT_METHODS.TRANSF_BANBIF,
  PAYMENT_METHODS.TRANSF_BANCO_PICHINCHA,
  PAYMENT_METHODS.TRANSF_BBVA,
  PAYMENT_METHODS.TRANSF_CITYBANK_PERU,
  PAYMENT_METHODS.TRANSF_INTERBANK_PERU,
  PAYMENT_METHODS.TRANSF_MIBANCO_PERU,
  PAYMENT_METHODS.SCOTIABANK_PERU,
  PAYMENT_METHODS.GNB_PERU,
  PAYMENT_METHODS.FALABELLA_PERU,
  PAYMENT_METHODS.RIPLEY_PERU,
  PAYMENT_METHODS.SANTANDER_PERU,
  PAYMENT_METHODS.CULQI_PERU,
  PAYMENT_METHODS.PAYPAL_PERU,
  PAYMENT_METHODS.MERCADOPAGO_PERU,
  PAYMENT_METHODS.NIUBIZ_PERU,
  PAYMENT_METHODS.IZIPAY_PERU,
  // COLOMBIA
  PAYMENT_METHODS.RAPI_PAGO_COL,
  PAYMENT_METHODS.PAYCO_COL,
  PAYMENT_METHODS.PAGO_ONLINE_COL,
  PAYMENT_METHODS.EFECTY_COL,
  PAYMENT_METHODS.MERCADO_PAGO_COL,
  PAYMENT_METHODS.SAFETY_PAY_COL,
  PAYMENT_METHODS.PAY_U_COL,
  PAYMENT_METHODS.PAYPAL_COL,
  PAYMENT_METHODS.TRANSF_BANCO_AGRA_COL,
  PAYMENT_METHODS.TRANSF_BANCO_AVVILLAS_COL,
  PAYMENT_METHODS.TRANSF_BANCO_CAJASOCIAL_COL,
  PAYMENT_METHODS.TRANSF_BANCO_OCCIDENTE_COL,
  PAYMENT_METHODS.TRANSF_BANCO_GRANAHORRAR_COL,
  PAYMENT_METHODS.TRANSF_BANCO_ITAU_COL,
  PAYMENT_METHODS.TRANSF_BANCO_PICHINCHA_COL,
  PAYMENT_METHODS.TRANSF_BANCO_POPULAR_COL,

  // ARGENTINA
  PAYMENT_METHODS.BANCO_NAC_ARGENTINA,
  PAYMENT_METHODS.BANCO_PROV_BNOS_AIRES,
  PAYMENT_METHODS.BANCO_GALICIA_BNOS_AIRES,
  PAYMENT_METHODS.BANCO_SANTANDER_ARGENTINA,
  PAYMENT_METHODS.BBVA_ARGENTINA,
  PAYMENT_METHODS.BANCO_PATAGONIA,
  PAYMENT_METHODS.HSBC_ARGENTINA,
  PAYMENT_METHODS.BANCO_SUPERVIELLE,
  PAYMENT_METHODS.BANCO_HIPOTECARIO,
  PAYMENT_METHODS.BANCO_COMAFI,
  PAYMENT_METHODS.BANCO_BICA,
  PAYMENT_METHODS.BANCO_COLUMBIA,
  PAYMENT_METHODS.BANCO_DEL_CHUBUT,
  PAYMENT_METHODS.BANCO_ENTRE_RIOS,
  PAYMENT_METHODS.BANCO_SAN_JUAN,
  PAYMENT_METHODS.BANCO_SANTIAGO_DEL_ESTERO,
  PAYMENT_METHODS.BANCO_TUCUMAN,
  PAYMENT_METHODS.BRUBANK,
];

const GROUPED_PAYMENT_METHODS = {
  CASH_BS: [
    PAYMENT_METHODS.CASH_BS,
  ],
  CASH_FE: [
    PAYMENT_METHODS.CASH_FE,
  ],
  DEBIT: [
    PAYMENT_METHODS.POS_BS,
  ],
  TRANSFER: [
    PAYMENT_METHODS.BANESCO,
    PAYMENT_METHODS.BINANCE,
    PAYMENT_METHODS.PAYPAL,
    PAYMENT_METHODS.TRANSFER,
    PAYMENT_METHODS.ZELLE,
  ],
  MOBILE: [
    PAYMENT_METHODS.MOBILE,
  ],
}

const CASH_COUNT_METHOD_PAYMENTS = [
  {
    value: PAYMENT_METHODS.CASH_BS,
    label: 'Efectivo (Bs)',
    name: 'cash_bs'
  },
  {
    value: PAYMENT_METHODS.CASH_FE,
    label: 'Efectivo (Divisas)',
    name: 'cash_fe'
  },
  {
    value: PAYMENT_METHODS.BINANCE,
    label: 'Binance',
    name: 'binance'
  },
  {
    value: PAYMENT_METHODS.TRANSFER,
    label: 'Transferencia (Bs)',
    name: 'transfer'
  },
  {
    value: PAYMENT_METHODS.ZELLE,
    label: 'Zelle',
    name: 'zelle'
  },
  {
    value: PAYMENT_METHODS.MOBILE,
    label: 'Pago Móvil',
    name: 'mobile'
  },
  {
    value: PAYMENT_METHODS.BANESCO,
    label: 'Banesco',
    name: 'banesco'
  },
  {
    value: PAYMENT_METHODS.PAYPAL,
    label: 'Paypal',
    name: 'paypal'
  },
  {
    value: PAYMENT_METHODS.POS_BS,
    label: 'Tarjeta de Débito',
    name: 'debit_bs'
  },
]

const SCANNER = "SCANNER"; // Para eventos de escaneo de código de barra

const PRODUCTS_TYPE = {
  NORMAL: 1,
  SIZE_COLOR: 2,
}

const PRODUCTS_TYPE_SALES = [
  {
    value: PRODUCTS_TYPE.NORMAL,
    label: 'Normal'
  },
  {
    value: PRODUCTS_TYPE.SIZE_COLOR,
    label: 'Por talla y color'
  }
]

const UNITS = [
  {
    value: 'Unidad',
    label: 'Unidad'
  },
  {
    value: 'Pieza',
    label: 'Pieza'
  },
  {
    value: 'Servicio',
    label: 'Servicio'
  },
  {
    value: 'Bulto',
    label: 'Bulto'
  },
  {
    value: 'Centimetro',
    label: 'Centimetro'
  },
  {
    value: 'Metro',
    label: 'Metro'
  },
  {
    value: 'Pulgada',
    label: 'Pulgada'
  },
  {
    value: 'Milimetro',
    label: 'Milimetro'
  },
  {
    value: 'Centimetro Cuadrado',
    label: 'Centimetro Cuadrado'
  },
  {
    value: 'Metro Cuadrado',
    label: 'Metro Cuadrado'
  },
  {
    value: 'Pulgada Cuadrada',
    label: 'Pulgada Cuadrada'
  },
  {
    value: 'Mililitro',
    label: 'Mililitro'
  },
  {
    value: 'Litro',
    label: 'Litro'
  },
  {
    value: 'Galón',
    label: 'Galón'
  },
  {
    value: 'Gramo',
    label: 'Gramo'
  },
  {
    value: 'Kilo',
    label: 'Kilo'
  },
  {
    value: 'Tonelada',
    label: 'Tonelada'
  },
  {
    value: 'Libra',
    label: 'Libra'
  },
  {
    value: 'Rollo',
    label: 'Rollo'
  },
  {
    value: 'Cuñete',
    label: 'Cuñete'
  },
  {
    value: 'Caja',
    label: 'Caja'
  },
  {
    value: 'Paquete',
    label: 'Paquete'
  },
  {
    value: 'Paila',
    label: 'Paila'
  },
  {
    value: 'Docena',
    label: 'Docena'
  },
]

export default {
  CONFIG,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_INV_COMMITTED,
  STATUS_INV_PROCESSED,
  STATUS_PAYMENT_INCOME,
  STATUS_PAYMENT_EXPENSES,
  STATUS_RESPONSE_SERVER,
  STATUS_RESPONSE_UNAUTHORIZED,
  ACTION_CLASS_ENTRY,
  ACTION_CLASS_OUTPUT,
  STATE_SUSPENDED,
  STATE_PROCESSED,
  STEP_SELECTION,
  STEP_FORM,
  STEP_SELLER_DATA,
  STEP_SELLER_CONF,
  TYPE_ENTRY,
  TYPE_OUTPUT,
  TYPE_PERSON,
  TYPE_ADJUSTMENT,
  TYPE_ADJUSTMENT_LIMITED,
  TYPE_DELIVERY,
  TYPE_BILL,
  TYPE_PAYMENTS_TRANSFER,
  TYPE_PRODUCT,
  TYPE_SALE,
  EXEMPT_ACTIVE,
  EXEMPT_INACTIVE,
  PURCHASE_BILL,
  PURCHASE_ORDER,
  ORDER_STATUS,
  TYPE_DOCUMENTS,
  SERIALIZATION,
  VALUE_ZERO,
  ROLE_ADMIN,
  ROLE_MODERATOR,
  ROLE_SELLER,
  ROLE_MANAGER,
  SCANNER,
  TYPE_IDENTITY,
  DISCOUNT_TYPES,
  SELLER_MODULES,
  TYPE_REPORT_CHARGES_STATUS,
  TYPE_BANKS,
  TYPE_BANK_ACCOUNTS,
  EXPENSES_INGRESS_PAYMENT_TYPE_LIST,
  EXPENSES_INGRESS_METHOD_TYPE_LIST,
  TYPE_BANK_ACCOUNTS_LIST,
  TYPE_BANKS_LIST,
  SALE_PAYMENT_TYPES,
  SALE_PAYMENT_PROCESSED_LIST,
  CASH_COUNT_METHOD_PAYMENTS,
  PAYMENT_METHODS,
  LOCAL_CURRENCIES,
  GROUPED_PAYMENT_METHODS,
  CLIENT: {
    STATUS: {
      ACTIVE: 1,
      INACTIVE: 0
    }
  },
  PAYMENT_TYPES,
  PRODUCTS_TYPE,
  PRODUCTS_TYPE_SALES,
  UNITS,
  WEB_ACCOUNTS_RECEIVABLE: 27,
	WEB_SELLERS: 28,
	WEB_MANAGERS: 29,
	WEB_MODERATORS: 30,
	WEB_PROVIDERS: 31,
	WEB_CLIENTS: 32,
	WEB_CONVERSION: 33,
	WEB_USERS: 34,
	WEB_INVENTORY_REPORT: 35,
	WEB_PRODUCTS: 36,
	WEB_REPORT_SALE: 37,
	WEB_QUOTES: 38,
	WEB_REPORT_SELLERS: 39,
	WEB_REPORT_PROVIDERS: 40,
  CARGA_MASIVA_PRODUCTS_ERROR: {
    CODE: 1,
    EMPTY_ALL_VALUES: 2,
    EMPTY_REQUIRED_ROW: 3,
    ROWS: 4,
    INVALID_UNIT: 5,
  },
  STATUS_SELLER: {
    ACTIVE: 1,
    INACTIVE: 0
  },
  VISIT_PLANNER_TYPE: {
    PLANNED: 1,
    UNPLANNED: 2,
  },
  VISIT_PLANNER_STATUS: {
    PENDING: 0,
    FINISH: 1,
    FAILED: -1
  },
  AUTOSALE_TYPE: {
    PLANNED: 1,
    UNPLANNED: 2,
  },
  AUTOSALE_STATUS: {
    PENDING: 0,
    FINISH: 1,
    FAILED: -1,
    REJECTED: 3,
    TEXT:{
      PENDING: 'Pendiente',
      FINISH: 'Finalizado',
      NOT_FINISH: 'No Finalizada',
    }
  },
  NEW_VISIT_PLANNER: 'new-visit-planner',
  ADMIN_FUNCTIONALITIES: {
    VISIT_PLANNER: 1
  },
  CURRENCIES: {
    DOLARES: 1,
    BOLIVARES: 2
  },
  COUNTRIES,
  PERMISSIONS: {
    MODERATOR: {
      PRODUCTS_CHANGE_PRICE: 'products/change-prices',
      PRODUCTS_CHANGE_QUANTITY: 'products/change-quantity',
      PRODUCTS_DELETE: 'products/delete',
      ORDERS_DELETE: 'orders/delete',
      ORDERS_CANCEL: 'orders/cancel',
      ORDERS_EDIT: 'orders/edit',
      ORDERS_ACCEPT_PAYMENT: 'orders/accept-payment',
      ORDERS_REJECT_PAYMENT: 'orders/reject-payment',
      ORDERS_DELETE_PAYMENT: 'orders/delete-payment',
    },
    MANAGER: {
      ORDERS_DELETE: 'orders/delete',
      ORDERS_CANCEL: 'orders/cancel',
    }
  },
  PRINTER: {
    TYPE: {
      FISCAL: 1,
      STANDARD: 2,
      BOTH: 3,
      FREE_FORM: 4,
    },
    FORMAT: {
      CARTA: 1,
      TICKET: 2,
      TICKET_58: 3,
      MEDIA_CARTA: 4,
    },
    STYLE: {
      STYLE_ONE: 1,
      STYLE_TWO: 2,
    },
    PRINT_NOTE: {
      NONE: 1,
      FISCAL: 2,
      STANDARD: 3,
    },
    TICKET_STYLE: {
      STYLE_ONE: 1,
      STYLE_TWO: 2,
    },
  },
  MODULES: {
    WEB_REPORT_SALE_CREATE: 44,
    WEB_FISCAL_SALE_CREATE: 69,
    WEB_REPORT_SALE_BILL: 83,
  },
  PRODUCT_EXENTO: {
    SI: 1,
    NO: 0
  },
  TAXES: {
    IVA: 16,
    IGTF: 3,
  },
	PAYMENT_ADMIN_CONFIRM: {
		PENDING: 0,
		APPROVED: 1,
		REJECTED: 2,
	},
  QUOTES: {
    SHOW_TAXES: {
      YES: 1,
      NO: 0,
    }
  },
  ORDERS: {
    SHOW_TAXES: {
      YES: 1,
      NO: 0,
    },
    FISCAL_BILL: {
      YES: 1,
      NO: 0,
    }
  },
  WAREHOUSES: {
    TYPE: {
      FIJO: 1,
      MOVIL: 2,
    }
  },
  PAYMENT_INDIVIDUAL: 1,
  PAYMENT_MULTIPLE: 2,
  PRODUCTS_DEFAULT_PRICE:{
    YES: 1,
    NO: 2,
  },
  PLAN_PAYMENT_METHODS: {
		PAYPAL: 1,
		STRIPE: 2,
		CASH: 3,
		MOVIL: 4,
		TRANSFER: 5
	},
	PLAN_PAYMENT_STATUS: {
		PAID: 1,
		TO_CONFIRM: 2,
		REJECTED: 3
	},
  DECIMAL_STOCK:{
    YES: 1,
    NO: 0,
  },
  PLANS: {
    PAYMENT_METHODS:{
      BNC_MOBILE: 4,
      SYSPAGO: 6,
      BNC_VPOS: 7,
    }
  },
  PHONE_CODES: [
    { value: '414', label: '414' },
    { value: '424', label: '424' },
    { value: '412', label: '412' },
    { value: '416', label: '416' },
    { value: '426', label: '426' }
  ],
  CARD_ACCOUNT_TYPES: [
    { value: '00', label: 'PRINCIPAL' },
    { value: '10', label: 'AHORRO' },
    { value: '20', label: 'CORRIENTE' },
  ],
  CARD_TYPES: [
    { value: 1, label: 'VISA' },
    { value: 2, label: 'MasterCard' },
    { value: 3, label: 'Débito Maestro' },
  ],
  DELIVERY_NOTE_STATUS: {
    PENDING: 0,
    GENERATED: 1
  },
  BILLING_STATUS: {
    PENDING: 0,
    INVOICED: 1
  }
};
