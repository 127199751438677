import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Input, Select } from '../../components';
import { axios, Constants, Globals } from '../../utils';
import LeftArrowIcon from '../../assets/icons/left-arrow.png';

const PaymentPaypal = ({ onGoBack, onClose, amount, methodInfo, conversion, plan, user, paypal_order_id, paypal_approve_url }) => {
    const [banks, setBanks] = useState([]);
    const [form, setForm] = useState({
        amount,
        amount_bs: parseFloat(parseFloat(amount * conversion).toFixed(2)),
        reference_number: '',
        bank_id: '',
        phone: '',
        phone_code: '',
        date: '',
        showOtpInput: false,
    });

    const handlePaypalPayment = () => {
        if (!paypal_approve_url) {
            Globals.showError("No se encontró la URL de aprobación de PayPal.");
            return;
        }
    
        // Abrir la URL de PayPal en una nueva pestaña
        //window.open(paypal_approve_url, '_blank');

        // Redirigir a la URL de PayPal en la misma ventana
        window.location.href = paypal_approve_url;
    };

    return (
        <section>
            <div className="back-button" onClick={onGoBack}>
                <img src={LeftArrowIcon} alt="Go Back" />
                <p className="hightlighted">PAYPAL</p>
            </div>

            <div className="summary">
                <p>Total a pagar</p>
                <p>{Globals.formatMiles(form.amount)}</p>
            </div>

            <br />
            <br />

            {paypal_approve_url ? (
                <button className="save-btn" onClick={handlePaypalPayment}>
                    Pagar con PayPal
                </button>
            ) : (
                <p>No se pudo generar el enlace de pago de PayPal.</p>
            )}
        </section>
    );
};

export default PaymentPaypal;