/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { on } from "jetemit";

import { Globals, Constants, Menus, axios } from "../utils";
import { Sidebar, Auth } from "../services";
import Logo from '../assets/img/logo.png';
import MenuIcon from '../assets/icons/menu.png';
import LogoutIcon from '../assets/icons/logout.png';

const sortAlphabetically = (a,b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
}

// import Socket from '../utils/socket';
class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      leftOpen: true,
      rightOpen: true,
      menu: [],
      sidebarCurrentCategory: localStorage.getItem('category'),
      // active: "Usuarios",
      admin_functionalities: []
    };
  }

  sidebarClosedRef = React.createRef(false);

  componentDidMount() {
    const { sidebar } = this.props;
    localStorage.setItem('path',this.props.history.location.pathname)
    this.maybeLoadMenu(sidebar);

    if (this.props.user) {
      axios
        .post("admin/visit-planners/check", {
          user_id: this.props.user?.id
        })
        .then(res => {
          if (res.data.admin?.admin_functionalities) {
            this.setState({
              admin_functionalities: res.data.admin?.admin_functionalities?.map(i => i.id) || []
            });
          }
        })
        .catch(() => {
          //
        }); 

      this.checkSession()
      this.checkPlanUser()
      this.checkModules()
      this.checkPermissions()
      this.getPrinter()

      on('update-sidebar', () => {
        this.maybeLoadMenu(sidebar);
      });
    }
  }

  checkPermissions = async () => {
    const { user } = this.props;
    try {
      const res = await Auth.checkPermissions({ user_id: user.id });
      if(res.user){
        this.props.dispatch({
          type: "SET_USER",
          payload: res.user
        });
      }

    } catch (error) {
      console.log('Menu -> checkPermissions -> Catch: ', error)
    }
  }

  checkModules = async () => {
    const { user } = this.props;
    try {
      Sidebar.getModuleByUser(user.id)
          .then(modules => this.setModules(user.level_id, user, modules))
          .catch(error => console.log({ error: error }))
          .finally(() => this.setState({ submitted: false }));
    } catch (error) {
      console.log('Menu -> checkModules -> Catch: ', error)
    }
  }

  setModules = (roleId, user, modules) => {
    const { id: userId } = user;

    switch (roleId) {
      case Constants.ROLE_ADMIN:
        this.props.dispatch({
          type: "SET_SIDEBAR",
          payload: modules
        });
        break;
      case Constants.ROLE_MODERATOR:
      case Constants.ROLE_MANAGER:
        if(modules.length > 0){
          this.props.dispatch({
            type: "SET_SIDEBAR",
            payload: modules
          });
        }
        else{
          //Globals.showError('No posee módulos asignados, comuníquese con el usuario Administrador');
        }
        break;
    }
  };

  checkPlanUser = async () => {
    const { user } = this.props;

    try {
      const res = await Auth.checkPlanUser({ user_id: user.id });
      if (!res.result){ 

        Globals.showError(res.error ?? 'Lo sentimos, su usuario ha sido bloqueado');

        this.props.dispatch({
          type: "QUIT_USER",
        });

        if (this.props.admin) {
          this.props.dispatch({
            type: "QUIT_ADMIN",
          });
        }

        this.props.dispatch({
          type: "QUIT_SIDEBAR",
        });

        this.props.dispatch({
          type: "REMOVE_TOKEN",
        });

        this.props.dispatch({
          type: "SET_SALES",
          payload: null,
        });

        this.props.dispatch({
          type: "SET_INVENTORY",
          payload: null,
        });

        this.props.dispatch({
          type: "SET_DELIVERY",
          payload: null,
        });

        this.props.history.push("/login");
        localStorage.clear() 
        
        
      }

    } catch (error) {
      console.log('Menu -> checkPlanUser -> Catch: ', error)
    }
  }

  checkSession = async () => {
    const token = JSON.parse(localStorage.getItem("token"))
    const { user } = this.props;

    try {
      const res = await Auth.checkSession({ user_id: user.id, token: token });
      if(res.message){
        Globals.showWarning(
          res.message
        );
      }
      if (res.result){ 
        //lo deslogueo
        this.props.dispatch({
          type: "QUIT_USER",
        });

        if (this.props.admin) {
          this.props.dispatch({
            type: "QUIT_ADMIN",
          });
        }

        this.props.dispatch({
          type: "QUIT_SIDEBAR",
        });

        this.props.dispatch({
          type: "REMOVE_TOKEN",
        });

        this.props.dispatch({
          type: "SET_SALES",
          payload: null,
        });

        this.props.dispatch({
          type: "SET_INVENTORY",
          payload: null,
        });

        this.props.dispatch({
          type: "SET_DELIVERY",
          payload: null,
        });

        this.props.history.push("/login");
        localStorage.clear() 
      }

    } catch (error) {
      console.log('App -> checkSession -> Catch: ', error)
    }
  }

  getPrinter = () => {
    axios
      .post("web/admin/printer-type", {
        admin_id: this.props.user.admin_id || this.props.user.id,
        user_id: this.props.user.id,
      })
      .then(async res => {
        if (res.data.printer_type) {
          this.props.dispatch({
            type: 'SET_PRINTER',
            payload: res.data.printer_type,
          });
        }
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  componentDidUpdate(prevProps) {
    if (prevProps.sidebar !== this.props.sidebar && this.props.sidebar) {
      this.maybeLoadMenu(this.props.sidebar);
    }
  }

  maybeLoadMenu = sidebar => {
    let sidebarNav = {
      Inventario: [],
      Productos: [],
      Cotizaciones: [],
      Ventas: [],
      Conversion: [],
      Ajustes: [],
      ProcesoCompra: [],
      Vendedores: [],
      Moderadores: [],
      Gerentes: [],
      Usuarios: [],
      Reportes: [],
      Pedidos: [],
      PedidosWhatsapp: [],
      PlanificadorVisita: [],
      IngresosGastosExtras: [],
      Dashboard: [],
      MiCuentaNegocio: [],
      Devoluciones: [],
      Comisiones: [],
      Compras: [],
      Almacenes: [],
      Personal: [],
      Cuentas: [],
    };

    sidebar
      .filter((Item) => Item.status === Constants.STATUS_ACTIVE)
      .forEach((Item) => {
        if (Item.menu_id === Menus[1].id && Menus[1].modules.includes(Item.id)) {
          sidebarNav["Inventario"].push(Item);
        } else if (Item.menu_id === Menus[2].id && Menus[2].modules.includes(Item.id)) {
          sidebarNav["Productos"].push(Item);
        } else if (Item.menu_id === Menus[3].id) {
          sidebarNav["Ventas"].push(Item);
        } else if (Item.menu_id === Menus[4].id) {
          const isEcuador = Globals.getCountry(this.props.user)?.id === Constants.COUNTRIES.ECUADOR;
          if(isEcuador) return;
          sidebarNav["Conversion"].push(Item);
        } else if (Item.menu_id === Menus[5].id) {
          sidebarNav["ProcesoCompra"].push(Item);
        } else if (Item.menu_id === Menus[6].id) {
          sidebarNav["Usuarios"].push(Item);
        } else if (Item.menu_id === Menus[7].id) {
          // si es /fiscalreport no lo agrego al menu
          if(Item.path !== '/fiscalreport'){
            sidebarNav["Reportes"].push(Item);
          }
        } 
        // else if (Item.menu_id === Menus[8].id) {
        //   sidebarNav["Vendedores"].push(Item);
        // } 
        // else if (Item.menu_id === Menus[9].id) {
        //   sidebarNav["Moderadores"].push(Item);
        // } 
        // else if (Item.menu_id === Menus[10].id) {
        //   sidebarNav["Gerentes"].push(Item);
        // } 
        else if (Item.menu_id === Menus[8].id && Menus[8].modules.includes(Item.id)) {
          const isVenezuela = Globals.getCountry(this.props.user)?.id === Constants.COUNTRIES.VENEZUELA;
          const isFiscalSale = Item.id === Constants.MODULES.WEB_FISCAL_SALE_CREATE;
          if (!isVenezuela && (isFiscalSale || Item.id === Constants.MODULES.WEB_REPORT_SALE_BILL)) return;

          sidebarNav["Pedidos"].push(Item);
          const newOrder = [];
          const hideNote = Number(this.props.printer?.print_note) === Constants.PRINTER.PRINT_NOTE.NONE;
          Menus[8].modules.forEach(item => {
            if (item === Constants.MODULES.WEB_REPORT_SALE_CREATE && hideNote) return;
            const exists = sidebarNav["Pedidos"].find((i) => i.id === item);
            if (exists) newOrder.push(exists);
          });
          sidebarNav["Pedidos"] = newOrder;
        } else if (Item.menu_id === Menus[9].id) {
          sidebarNav["PedidosWhatsapp"].push(Item);
        } else if (Item.menu_id === Menus[19].id && Menus[19].modules.includes(Item.id)) {
          sidebarNav["Cuentas"].push(Item);
        } else if (Item.menu_id === Menus[10].id && Menus[10].modules.includes(Item.id)) {
          sidebarNav["PlanificadorVisita"].push(Item);
        } else if (Item.menu_id === Menus[11].id && Menus[11].modules.includes(Item.id)) {
          // SI ES EL DE IVA
          if(Item.id == 52){
            const can_include_taxes = Globals.canIncludeTaxes(this.props.user)
            if(can_include_taxes == true){
              sidebarNav["Ajustes"].push(Item);
            }
          }
          else{
            sidebarNav["Ajustes"].push(Item);
          }
        } else if (Item.menu_id === Menus[12].id) {
          sidebarNav["IngresosGastosExtras"].push(Item);
        } else if (Item.menu_id === Menus[13].id) {
          sidebarNav["Dashboard"].push(Item);
        } else if (Item.menu_id === Menus[14].id) {
          sidebarNav["MiCuentaNegocio"].push(Item);
        } else if (Item.menu_id === Menus[15].id) {
          sidebarNav["Comisiones"].push(Item);
        } else if (Item.menu_id === Menus[16].id) {
          sidebarNav["Almacenes"].push(Item);
        } else if (Item.menu_id === Menus[18].id) {
          sidebarNav["Cotizaciones"].push(Item);
        }
        else if (Item.menu_id === Menus[17].id && Menus[17].modules.includes(Item.id)) {
          sidebarNav["Personal"].push(Item);
        }
        
      });

    // Verificar si this.props.user.printer_type está definido y si su tipo es FISCAL
    const user = this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user : this.props.user?.admin;
    if (user?.printer_type && user.printer_type.type === Constants.PRINTER.TYPE.FISCAL) {
      const fiscalReportItem = {
        id: 'fiscalReport',
        name: 'Reporte Fiscal',
        path: '/fiscalreport',
      };

      if (this.props.user.level_id === Constants.ROLE_ADMIN) {
        // Agregar el nuevo elemento al final de sidebarNav["Reportes"]
        sidebarNav["Reportes"].push(fiscalReportItem);
      } else if (this.props.user.level_id === Constants.ROLE_MODERATOR) {
        const hasFiscalReportModule = this.props.user.modules.some(module => module.path === '/fiscalreport');
        if (hasFiscalReportModule) {
          sidebarNav["Reportes"].push(fiscalReportItem);
        }
      }
    }
    

    this.setState({ menu: sidebarNav, showMenu: true });
  };

  renderName = () => {
    const { user } = this.props;
    let name = "";
    if (user) {
      if (user.person) {
        name = user.person.first_name;
      }
      return name;
    }
  };
  renderCode = () => {
    const { user } = this.props;
    let code;
    if (user) {
      if (user.person) {
        code = user.person.code;
      }
      return code;
    }
  };

  logout = () => {
    Globals.confirm("¿Desea cerrar sesión?", () => {
      Globals.setLoading();
      setTimeout(() => {
        const { user } = this.props;
        Auth.logoutWeb(user)
        .then(response => {
          if (response.result) {
            this.props.dispatch({
              type: "QUIT_USER",
            });
    
            if (this.props.admin) {
              this.props.dispatch({
                type: "QUIT_ADMIN",
              });
            }
    
            this.props.dispatch({
              type: "QUIT_SIDEBAR",
            });
    
            this.props.dispatch({
              type: "REMOVE_TOKEN",
            });
    
            this.props.dispatch({
              type: "SET_SALES",
              payload: null,
            });
    
            this.props.dispatch({
              type: "SET_INVENTORY",
              payload: null,
            });
    
            this.props.dispatch({
              type: "SET_DELIVERY",
              payload: null,
            });
    
            this.props.history.push("/login");
            localStorage.clear()
          }
        })
        .catch(error => {
          Globals.showError(error?.error);
        })

        Globals.quitLoading();
      }, 1500);
    });
  };

  backAdmin = () => {
    let message = "¿Desea regresar como administrador?";
    if (this.props.admin.role === 2)
      message = "¿Desea regresar como superadministrador?";

    Globals.confirm(message, () => {
      Globals.setLoading();

      this.props.dispatch({
        type: "SET_USER",
        payload: this.props.admin,
      });

      setTimeout(() => {
        Sidebar.getModules(this.props.admin.role)
          .then((response) => {
            this.props.dispatch({
              type: "SET_SIDEBAR",
              payload: response,
            });

            this.props.dispatch({
              type: "SET_SALES",
              payload: null,
            });

            this.props.dispatch({
              type: "SET_INVENTORY",
              payload: null,
            });

            this.props.dispatch({
              type: "SET_DELIVERY",
              payload: null,
            });

            if (this.props.admin.role === 2 || this.props.admin.role === 1) {
              this.props.history.push("/enterprises");
            }
          })
          .catch()
          .finally(() => {
            Globals.quitLoading();
          });
      }, 1000);
    });
  };

  searchPath = (path) => {
    let pathC = this.props.user.permissions.find((i) => i.path === path);
    if (pathC) return true;
    else return false;
  };

  handleToggle = (hasSubmenu = false) => {
    if (!this.sidebarClosedRef.current || (this.sidebarClosedRef.current && !hasSubmenu)) return;
    this.toggleSidebar()
  }

  toggleSidebar = () => {
    this.sidebarClosedRef.current = !this.sidebarClosedRef.current;
    const sidebar = document.getElementById('sidebar');

    if (this.sidebarClosedRef.current) {
      const submenus = document.querySelectorAll('ul.navbar-collapse');
      for (let index = 0; index < submenus.length; index++) {
        submenus[index].classList.remove('show');
        submenus[index].previousSibling.ariaExpanded = false;
      }

      sidebar.classList.add('closed');

    } else {
      sidebar.classList.remove('closed');
    }
  };

  getBreadcumbs = () => {
    return '';
  }

  getTitle = () => {
    let route;
    Object.values(this.state.menu).forEach(r => {
      const found = r.find(x => String(x.path) === this.props.history.location.pathname)
      if (found) {
        if (found.id === 51 && this.props.user.level_id === Constants.ROLE_ADMIN) {
          route = found.name;
        } else if (found.id === 51) {
          route = 'Mi Cuenta';
        } else {
          route = found.name;
        }
      }
    });
    return route || '';
  }

  /* setActive = (Item) => {
    console.log(Item)
    this.setState({ active: Item.name });
  } */

  isActiveModule = (routes) => {
    return routes.find(x => x.path === this.props.history.location.pathname);
  }

  isActiveRoute = (item) => {
    return this.props.history.location.pathname === item?.path ? 'active' : '';
  }

  render() {
    // let leftOpen = this.state.leftOpen ? "open" : "closed";
    // let rightOpen = this.state.rightOpen ? "open" : "closed";

    const { sidebar, history, user, admin } = this.props;
    const { menu, showMenu, active, leftOpen, admin_functionalities } = this.state;
    
    let entAdmin = false;
    if (admin && admin.id !== user.id) entAdmin = true;
    let EntLogOut;

    if (entAdmin) {
      EntLogOut = (
        <button
          className="btn btn-default btn-logout-admin"
          onClick={this.backAdmin}
        >
          <i className="fa fa-sign-out"></i>
        </button>
      );
    }
    return (
      <div id="layout">
        <header>
          <div className="logo">
            <img src={Logo} style={{ width: "90%", padding: "0.5rem 2rem" }} alt="SYSGE24"/>

            <button
              className="toggle-menu"
              onClick={() => this.toggleSidebar()}
              title="Ocultar Menú"
            >
              <img src={MenuIcon} alt="Ocultar Menú" />
            </button>
          </div>
          <div className="content-header">
            <div className="group">
              {!!this.props.leftSlot && this.props.leftSlot}
              {!this.props?.hideContentBreadcumbs && (
                <div className="content-breadcumbs">
                  <div className="breadcumbs">{this.getBreadcumbs()}</div>
                  <div className="title">{this.getTitle()}</div>
                </div>
              )}
            </div>
            {!!this.props.rightSlot && this.props.rightSlot}
            <div className="logout">
              <div onClick={this.logout}>
                <img src={LogoutIcon} alt="Cerrar sesión" />
                <span>Cerrar Sesión</span>
              </div>
            </div>
          </div>
        </header>

        <div className="container-app">
          <div id="left">
            <div id="sidebar">
              <div className="user">
                <div>
                  {/* <button
                    className="toggle-menu"
                    onClick={() => this.toggleSidebar()}
                    title="Ocultar Menú"
                  >
                    <img src={MenuIcon} alt="Ocultar Menú" />
                  </button> */}

                  {/* <div className="info">
                    <p>{ this.props.user?.admin?.name }</p>
                    <p>{ this.props.user.name }</p>
                  </div> */}
                </div>
                {/* <div className="divider" /> */}
              </div>
              <div className="container-menu">
                <div className="menu">
                  <Scrollbars
                    autoHide={true}
                    renderTrackHorizontal={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{ ...style, backgroundColor: " blue " }}
                      />
                    )}
                  >

                    <ul className="items">
                      {showMenu && menu["Dashboard"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["Dashboard"][0])}>
                          <Link to={menu["Dashboard"][0].path}>
                            <button className="icon-button" title={Menus[13].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[13].logo} alt={Menus[13].name} />
                            </button>
                            <span>{Menus[13].name}</span>
                          </Link>
                        </li>
                      ) : null}

                      {showMenu && menu["MiCuentaNegocio"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["MiCuentaNegocio"][0])}>
                          <Link to={menu["MiCuentaNegocio"][0].path}>
                            <button className="icon-button" title={this.props.user.level_id === Constants.ROLE_ADMIN ? Menus[14].name : 'Mi Cuenta'} onClick={() => this.handleToggle()}>
                              <img src={Menus[14].logo} alt={this.props.user.level_id === Constants.ROLE_ADMIN ? Menus[14].name : 'Mi Cuenta'} />
                            </button>
                            <span>{this.props.user.level_id === Constants.ROLE_ADMIN ? Menus[14].name : 'Mi Cuenta'}</span>
                          </Link>
                        </li>
                      ) : null}

                      {showMenu && menu["Inventario"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["Inventario"]))}>
                          <a
                            href="#menu-item-inventory"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["Inventario"])}
                            className={!!this.isActiveModule(menu["Inventario"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[1].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[1].logo} alt={Menus[1].name} />
                            </button>
                            <span>{Menus[1].name}</span>
                          </a>
                          <ul id="menu-item-inventory" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["Inventario"]) ? 'show':''}`}>
                            {menu["Inventario"].map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {showMenu && menu["Productos"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["Productos"]))}>
                          <a
                            href="#menu-item-products"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["Productos"])}
                            className={!!this.isActiveModule(menu["Productos"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[2].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[2].logo} alt={Menus[2].name} />
                            </button>
                            <span>{Menus[2].name}</span>
                          </a>
                          <ul id="menu-item-products" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["Productos"]) ? 'show':''}`}>
                            {menu["Productos"].map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {showMenu && menu["Cotizaciones"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["Cotizaciones"][0])}>
                          <Link to={menu["Cotizaciones"][0].path}>
                            <button className="icon-button" title={Menus[18].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[18].logo} alt={Menus[18].name} />
                            </button>
                            <span>{ Menus[18].name }</span>
                          </Link>
                        </li>
                      ) : null}

                      {showMenu && menu["Almacenes"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["Almacenes"]))}>
                          <a
                            href="#menu-item-almacenes"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["Almacenes"])}
                            className={!!this.isActiveModule(menu["Almacenes"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[16].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[16].logo} alt={Menus[16].name} />
                            </button>
                            <span>{Menus[16].name}</span>
                          </a>
                          <ul id="menu-item-almacenes" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["Almacenes"]) ? 'show' : ''}`}>
                            {menu["Almacenes"].map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {showMenu && menu["Ventas"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["Ventas"]))}>
                          <a
                            href="#menu-item-sales"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["Ventas"])}
                            className={!!this.isActiveModule(menu["Ventas"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[3].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[3].logo} alt={Menus[3].name} />
                            </button>
                            <span>{Menus[3].name}</span>
                          </a>
                          <ul id="menu-item-sales" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["Ventas"]) ? 'show':''}`}>
                            {menu["Ventas"].map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {showMenu && menu["Pedidos"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["Pedidos"]))}>
                          <a
                            href="#menu-item-request"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["Pedidos"])}
                            className={!!this.isActiveModule(menu["Pedidos"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[8].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[8].logo} alt={Menus[8].name} />
                            </button>
                            <span>{Menus[8].name}</span>
                          </a>
                          <ul id="menu-item-request" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["Pedidos"]) ? 'show':''}`}>
                            {menu["Pedidos"].map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {showMenu && menu["PedidosWhatsapp"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["PedidosWhatsapp"][0])}>
                          <Link to={menu["PedidosWhatsapp"][0].path}>
                            <button className="icon-button" title={Menus[9].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[9].logo} alt={Menus[9].name} />
                            </button>
                            <span>{Menus[9].name}</span>
                          </Link>
                        </li>
                      ) : null}

                      {showMenu && menu["Cuentas"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["Cuentas"]))}>
                          <a
                            href="#menu-item-accounts"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["Cuentas"])}
                            className={!!this.isActiveModule(menu["Cuentas"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[19].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[19].logo} alt={Menus[19].name} />
                            </button>
                            <span>{Menus[19].name}</span>
                          </a>
                          <ul id="menu-item-accounts" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["Cuentas"]) ? 'show':''}`}>
                            {menu["Cuentas"].map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {showMenu && menu["Conversion"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["Conversion"][0])}>
                          <Link to={menu["Conversion"][0].path}>
                            <button className="icon-button" title={Menus[4].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[4].logo} alt={Menus[4].name} />
                            </button>
                            <span>{Menus[4].name}</span>
                          </Link>
                        </li>
                      ) : null}

                      {showMenu && menu["ProcesoCompra"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["ProcesoCompra"]))}>
                          <a
                            href="#menu-item-purchase"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["ProcesoCompra"])}
                            className={!!this.isActiveModule(menu["ProcesoCompra"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[5].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[5].logo} alt={Menus[5].name} />
                            </button>
                            <span>{Menus[5].name}</span>
                          </a>
                          <ul id="menu-item-purchase" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["ProcesoCompra"]) ? 'show':''}`}>
                            {menu["ProcesoCompra"].sort(sortAlphabetically).map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {/* {showMenu && menu["Vendedores"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["Vendedores"][0])}>
                          <Link to={menu["Vendedores"][0].path}>
                            <button className="icon-button" title={Menus[8].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[8].logo} alt={Menus[8].name} />
                            </button>
                            <span>{Menus[8].name}</span>
                          </Link>
                        </li>
                      ) : null} */}

                      {showMenu && menu["Comisiones"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["Comisiones"][0])}>
                          <Link to={menu["Comisiones"][0].path}>
                            <button className="icon-button" title={Menus[15].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[15].logo} alt={Menus[15].name} />
                            </button>
                            <span>{Menus[15].name}</span>
                          </Link>
                        </li>
                      ) : null}

                      {/* {showMenu && menu["Moderadores"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["Moderadores"][0])}>
                          <Link to={menu["Moderadores"][0].path}>
                            <button className="icon-button" title={Menus[9].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[9].logo} alt={Menus[9].name} />
                            </button>
                            <span>{Menus[9].name}</span>
                          </Link>
                        </li>
                      ) : null} */}

                      {/* {showMenu && menu["Gerentes"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["Gerentes"][0])}>
                          <Link to={menu["Gerentes"][0].path}>
                            <button className="icon-button" title={Menus[10].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[10].logo} alt={Menus[10].name} />
                            </button>
                            <span>{Menus[10].name}</span>
                          </Link>
                        </li>
                      ) : null} */}

                      {/* {showMenu && menu["Usuarios"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["Usuarios"][0])}>
                          <Link to={menu["Usuarios"][0].path}>
                            <button className="icon-button" title={Menus[6].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[6].logo} alt={Menus[6].name} />
                            </button>
                            <span>{Menus[6].name}</span>
                          </Link>
                        </li>
                      ) : null} */}

                      {showMenu && menu["Personal"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["Personal"]))}>
                          <a
                            href="#menu-item-personal"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["Personal"])}
                            className={!!this.isActiveModule(menu["Personal"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[17].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[17].logo} alt={Menus[17].name} />
                            </button>
                            <span>{Menus[17].name}</span>
                          </a>
                          <ul id="menu-item-personal" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["Personal"]) ? 'show' : ''}`}>
                            {menu["Personal"].map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {showMenu && menu["IngresosGastosExtras"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["IngresosGastosExtras"][0])}>
                          <Link to={menu["IngresosGastosExtras"][0].path}>
                            <button className="icon-button" title={Menus[12].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[12].logo} alt={Menus[12].name} />
                            </button>
                            <span>{Menus[12].name}</span>
                          </Link>
                        </li>
                      ) : null}

                      {showMenu && menu["Reportes"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["Reportes"]))}>
                          <a
                            href="#menu-item-reports"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["Reportes"])}
                            className={!!this.isActiveModule(menu["Reportes"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[7].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[7].logo} alt={Menus[7].name} />
                            </button>
                            <span>{Menus[7].name}</span>
                          </a>
                          <ul id="menu-item-reports" className={`navbar-collapse collapse ${this.isActiveModule(menu["Reportes"]) ? 'show' : ''}`}>
                            {menu["Reportes"].map((Item, key) => {
                              if (Item.path == "/report-visit-planners" && admin_functionalities.indexOf(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) === -1) {
                                return null; // No renderizar el elemento si no se cumple la condición
                              }
                              return <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />;
                            })}
                          </ul>
                        </li>
                      ): null}

                      {showMenu && menu["Ajustes"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["Ajustes"]))}>
                          <a
                            href="#menu-item-ajustes"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["Ajustes"])}
                            className={!!this.isActiveModule(menu["Ajustes"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[11].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[11].logo} alt={Menus[11].name} />
                            </button>
                            <span>{Menus[11].name}</span>
                          </a>
                          <ul id="menu-item-ajustes" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["Ajustes"]) ? 'show':''}`}>
                            {menu["Ajustes"].map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {/* {admin_functionalities.indexOf(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) != -1 && showMenu && menu["PlanificadorVisita"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["PlanificadorVisita"][0])}>
                          <Link to={menu["PlanificadorVisita"][0].path}>
                            <button className="icon-button" title={Menus[10].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[10].logo} alt={Menus[10].name} />
                            </button>
                            <span>{Menus[10].name}</span>
                          </Link>
                        </li>
                      ) : null} */}

                      {admin_functionalities.indexOf(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) != -1 && showMenu && menu["PlanificadorVisita"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(this.isActiveModule(menu["PlanificadorVisita"]))}>
                          <a
                            href="#menu-item-planificadorvisita"
                            data-toggle="collapse"
                            aria-expanded={!!this.isActiveModule(menu["PlanificadorVisita"])}
                            className={!!this.isActiveModule(menu["PlanificadorVisita"])
                              ? 'dropdown-toggle'
                              : 'dropdown-toggle collapsed'
                            }
                          >
                            <button className="icon-button" title={Menus[10].name} onClick={() => this.handleToggle(true)}>
                              <img src={Menus[10].logo} alt={Menus[10].name} />
                            </button>
                            <span>{Menus[10].name}</span>
                          </a>
                          <ul id="menu-item-planificadorvisita" className={`navbar-collapse collapse ${!!this.isActiveModule(menu["PlanificadorVisita"]) ? 'show' : ''}`}>
                            {menu["PlanificadorVisita"].map((Item, key) =>
                              <CustomLink key={key} item={Item} isActiveRoute={this.isActiveRoute} />
                            )}
                          </ul>
                        </li>
                      ) : null}

                      {showMenu && menu["Devoluciones"]?.length > 0 ? (
                        <li className={'li-' + this.isActiveRoute(menu["Devoluciones"][0])}>
                          <Link to={menu["Devoluciones"][0].path}>
                            <button className="icon-button" title={Menus[18].name} onClick={() => this.handleToggle()}>
                              <img src={Menus[18].logo} alt={Menus[18].name} />
                            </button>
                            <span>{Menus[18].name}</span>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

const CustomLink = ({ item, isActiveRoute }) => (
  <li className={isActiveRoute(item)}>
    <Link className="nav-link" to={item.path}>
      {item.name}
    </Link>
  </li>
);

export default connect((state) => {
  return {
    user: state.user,
    support: state.support,
    admin: state.admin,
    sidebar: state.sidebar,
    inventory: state.inventory,
    sales: state.sales,
    delivery: state.delivery,
    printer: state.printer,
  };
})(Menu);
