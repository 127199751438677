import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input,
  DatePicker,
} from "../../components";
import { axios, Globals, Colors, Constants, ENV } from "../../utils";
import { User as Users, Sidebar } from "../../services";
import CreateEditQuote from "./create_edit_quote";
import ViewQuote from "./view_quote";
import AddIcon from '../../assets/icons/add.png';
import ConverToOrderIcon from '../../assets/icons/convert-to-order.png';
import ConverToOrderSVG from '../../assets/icons/convertToOrder.svg';
import globals from "../../utils/globals";
// import socket from '../../utils/socket';
import ConvertToOrder from '../whatsapp/convert-to-order';
import moment from 'moment';
import CheckIcon from '../../assets/icons/check.png';

class Quotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      header: [
        // "",
        "ID",
        "Cliente",
        "Vendedor",
        "Fecha de Emisión",
        "Fecha de Vencimiento",
        "Total",
        "Acciones"
      ],
      page: 1,
      last_page: 1,
      data: [],
      user: null,
      view: false,
      form: {
        status: null,
        search: null,
        user_id: this.props.user.id,
        role: this.props.user.role,
        since: "",
        until: "",
        web: true,
        admin_id: this.props.user.admin_id != null ? this.props.user.admin_id : this.props.user.id
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
      convertModal: false,
      item: [],
      client: [],
      methods: [],
      // ids_selected: []
    };
  }

  abortController = new AbortController()

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
  
  load = () => {
    Globals.setLoading();
    let param = this.state.form;
    param.user_id = this.props.user.id;
    axios
      .post("web/admin/quotes?page=" + this.state.page, param)
      .then(res => {
        if (res.data.quotes) {
          this.setState({
            last_page: res.data.quotes.last_page,
            data: res.data.quotes.data,
            methods: res.data?.payment_methods,
            id_selected: []
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
    
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Inactivo",
          color: Colors.red
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;

      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = () => {
    this.setState({
      create: false,
      view: false,
      edit: null,
      user: null,
      convertModal: false,
    });

    this.load();
  };

  inactiveUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea inactivar a: ${name}?`, () => {
      Users.setStatusInactive({  id: Item.id })
        .then(response => {
          // socket.emit('disable-user',{id: item.id});
          this.load();
          Globals.showSuccess("¡cotización inactivado exitosamente!");
        })
        .catch(error => {
          Globals.showError(error);
        });
    });
  };

  activateUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea activar a: ${name}?`, () => {
      Users.setStatusActive({ Id: Item.id })
        .then(response => {
          //socket.emit('activate-user', { id: item.id });
          this.load();
          Globals.showSuccess("¡cotización activado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  verifyUser = Item => {
    const { person } = Item;
    const name = person.first_name;

    Globals.confirm(`¿Desea verificar a: ${name}?`, () => {
      Users.setStatusVerify({ Id: Item.id })
        .then(response => {
          this.load();
          Globals.showSuccess("¡cotización verificado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  delete = (quote_id) => {
    Globals.confirm("¿Seguro de eliminar la cotización?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post(`admin/quotes/delete`, { quote_id })
        .then(res => {
          Globals.showSuccess('Cotización eliminado');
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  set = (item, i) => {
    Globals.confirm(
      "¿Desea seleccionar al cotización multiempresa: " + item.person.first_name + "?",
      () => {
        Globals.setLoading("Guardando...");
        axios
          .post("admin/users/get-user-data", { id: item.id })
          .then(res => {
            this.props.dispatch({
              type: "SET_USER",
              payload: res.data.user
            });
            Sidebar.getModules(res.data.user.role).then(response => {
              this.props.dispatch({
                type: "SET_SIDEBAR",
                payload: response
              });
              this.props.history.push("/enterprises");
            });
          })
          .catch(err => {
            Globals.showError(err.response.data.msg);
          })
          .then(() => {
            Globals.quitLoading();
          });
      }
    );
  };

  print = (id) => {
    let param = this.state.form;
    param.quote_id = id
    param.web = true
    axios
      .post("admin/quotes/download", param)
      .then(res => {
        if(res.data.url){
          const url = `${res.data.url}`;
          const win = window.open(url, "_blank");
          win.focus();
        }
        else{
          Globals.showError('Ha ocurrido un error');
        }
      })
      .catch(err => {
        Globals.showError();
      });
    
  };

  ConvertToOrderFunction = (item) => {
    item.details?.map((i) => {
			i.selected_price = i.price;
      i.discount = i.discount_percentage;
		});
    item.discount = item.discount_percentage;
    this.setState({ convertModal: true, item, client: item.client });
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      //search: '',
      form: {
        status: null,
        search: '',
        user_id: this.props.user.id,
        role: this.props.user.role,
        since: "",
        until: "",
        web: true,
        admin_id: this.props.user.admin_id != null ? this.props.user.admin_id : this.props.user.id
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
    }))
    await this.load();
  };

  convertToOrder = () => {
    Globals.confirm('¿Desea convertir las cotizaciones seleccionadas en ordenes?',async () => {

    });
  }

  render() {
    const {history} = this.props
    const { create, data, edit, header, search, status, user, view, convertModal, } = this.state
    // const titleTable = this.props.user.role === 1 ? 'Multiempresas' : 'Cotizaciones';
    // const userTypeText = this.props.user.role === 1 ? 'Multiempresa' : 'cotización';
    const titleTable = 'Cotizaciones';
    const userTypeText = 'cotización';
    return (
      <Menu history={history}>
        {view && (
          <Modal title={`Ver ${userTypeText}`} onClose={this.close} visible>
            <ViewQuote user={user} />
          </Modal>
        )}

        {create && (
          <Modal
            title={edit ? `Editar ${userTypeText}` : `Nueva ${userTypeText}`}
            onClose={this.close}
            visible
          >
            <CreateEditQuote 
              edit={edit}
              onClose={this.close} 
              user={this.props.user}
            />
          </Modal>
        )}

        {convertModal && (
					<Modal
						title="Enviar a pedido"
						onClose={this.close} 
						visible
					>
						<ConvertToOrder
							client={ this.state.client }
							item={ this.state.item }
							methods={ this.state.methods }
							onClose={ this.close }
              quote={true}
						/>
					</Modal>
				)}

        <div id="home" className="quotes">
          <div className="row">
            <div className="col-md-3">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.handleChange}
                value={this.state.form.search}
                placeholder="Buscar por Nombre de cliente o Vendedor"
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-3" style={{ display: 'flex', padding: 0, gap: '1rem' }}>
              <Button
                className="btn-align-bottom btn-filter"
                color=" "
                style={{ minWidth: 110 }}
                onClick={async () => {
                  await this.setState({ page: 1 });
                  this.load();
                }}
              >
                <span>Filtrar</span>
              </Button>
              <Button
                className="btn-align-bottom btn-filter"
                color=" "
                style={{ minWidth: 110 }}
                onClick={this.returnState}
              >
                <span>Limpiar</span>
              </Button>
            </div>
          </div>

          {/*{
            this.state.ids_selected.length > 0 && (
              <button className="btn btn-convert-order" onClick={ () => this.convertToOrder() }>
                Convertir a orden
              </button>
            )
          }*/}

          <Table
            data={data.length}
            title={titleTable}
            header={header}
            right={
              <Button
                title="Agregar cotización"
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <img src={AddIcon} alt="Agregar cotización" />
              </Button>
            }
          >
            {data.map((Item, index) => {
              {/*const index_checked = this.state.ids_selected.indexOf(Item.id);
              const is_checked = index_checked != -1;*/}

              return (
                <tr key={index}>
                  {/*<td>
                    {(
                      moment(Item.expiration_date) > moment() && (this.props.user.level_id === Constants.ROLE_ADMIN || this.props.user.level_id === Constants.ROLE_MODERATOR)
                    ) && (
                      <div className={ `container-check ${ is_checked ? 'active' : '' }` } onClick={ () => {
                         let ids_selected = [...this.state.ids_selected];
                         if (is_checked) {
                           ids_selected.splice(index_checked,1);
                         }
                         else {
                           ids_selected.push(Item.id);
                         }
                         this.setState({
                           ids_selected
                         });
                      } }>
                        {
                          is_checked && (
                            <img src={ CheckIcon } />
                          )
                        }
                      </div>
                    )}
                  </td>*/}
                  <td>{Item.number_format}</td>
                  <td>{Item.client?.name ?? 'No tiene'}</td>
                  <td>{Item.seller?.name ?? 'No tiene'}</td>
                  <td> {Item.created_es} </td>
                  <td> {Item.expiration_date_es} </td>
                  <td> {parseFloat(Number(Item.total)).toFixed(2)} </td>
                  <td>
                    {moment(Item.expiration_date) > moment()  && (
                        <Button
                          title="Editar"
                          small="true"
                          color=" "
                          style={{backgroundColor: '#0f54e0'}}
                          onClick={() =>
                            this.setState(
                              {
                                edit: {
                                  element: Item,
                                  index: index
                                }
                              },
                              () => {
                                this.setState({ create: true });
                              }
                            )
                          }
                        >
                          <Icon name="edit" />
                        </Button>
                    )}
                      <Button
                        color="info"
                        title="Descargar"
                        small="true"
                        onClick={() => this.print(Item.crypt)}
                      >
                        <Icon name="download" />
                      </Button>
                      {moment(Item.expiration_date) > moment()  && (
                        <Button
                          color="green"
                          title="Convertir a orden"
                          small="true"
                          onClick={() => this.ConvertToOrderFunction(Item)}
                        >
                          <img src={ConverToOrderIcon} style={{ width: 15 }} alt="Convertir a orden" />
                        </Button>
                      )}
                      <Button
                        color="red"
                        title="Eliminar"
                        small="true"
                        onClick={() => this.delete(Item.id)}
                      >
                        <Icon name="trash" />
                      </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => this.load()
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Quotes);
