import React from 'react';
import { connect } from 'react-redux';
import Menu from '../menu';
import { Constants, Globals, axios } from '../../utils';
import { PlanService, Auth as AuthService } from '../../services';
import LeftArrowIcon from '../../assets/icons/left-arrow.png';
import PaymentMethodIcon from '../../assets/icons/payment-method.png';
import PaymentDataIcon from '../../assets/icons/payment-data.png';
import PaymentSummaryIcon from '../../assets/icons/payment-summary.png';
import PaymentMobileIcon from '../../assets/icons/payment-mobile.png';
import PlanPaymentMobile from './plan-payment-mobile';
import PlanPaymentPaypal from './plan-payment-paypal';
import PlanPaymentSyspago from './plan-payment-syspago';
import ENV from "../../utils/env";

const STEP = {
  METHOD: 1,
  FORM: 2,
};

class PlanPayment extends React.Component {
  state = {
    step: STEP.METHOD,
    loading: true,
    form: {
      user_id: this.props.user.id,
      payment_method: null,
      amount: 0,
    },
    banks: [],
    phone_codes: [],
    methodInfo: null,
    plan: null,
    current_date: null,
    global_conversion: null,
    trial_expiration: null,
    paypal_order_id: null,
    paypal_approve_url: null,
    paypal_token: null,
    paypal_payerId: null,
    paypal_processed: false,
    payment_methods: []
  }

  componentDidMount() {
    // Logica necesaria cuando regresen del pago en PayPal
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split('?')[1]);
    const paypal_token = params.get('token');
    const paypal_payerId = params.get('PayerID');

    console.log("paypal_token: ", paypal_token);
    console.log("paypal_payerId: ", paypal_payerId);

    this.setState({
      paypal_token,
      paypal_payerId,
      paypal_processed: false, // Inicializamos en falso
    });

    this.load();
    this.loadMethods();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate');
    console.log('prevState: ', prevState);

    const paypalData = JSON.parse(localStorage.getItem("paypal_data"));

    if (!this.state.paypal_processed && this.state.paypal_token && this.state.paypal_payerId && paypalData?.paypal_order_id) {
      console.log('Procesando pago en PayPal...');
      this.setState({ paypal_processed: true }, () => {
        this.capturePaypalPayment(paypalData?.paypal_order_id);
      });
    }
  }

  loadMethods = async () => {
    const res = await AuthService.getMethods({
      user_id: this.props.user?.id
    });
    this.setState({
      payment_methods: res.methods || []
    });
  }

  load = async () => {
    const res = await PlanService.get({
      user_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
    });
    await this.setState({
      loading: false,
      plan: res.plan,
      current_date: res.current_date,
      global_conversion: res.global_conversion,
      trial_expiration: res.trial_expiration,
      methodInfo: {
        document: res.document,
        phone: res.phone,
        bank: res.bank,
      }
    });

    const amount = res.plan?.payment?.year ? (res.plan?.price_year * 12) : res.plan?.price;
    this.change(amount, 'amount');
  }

  change = async (value, target) => {
    await this.setState((state) => ({
      form: { ...state.form, [target]: value }
    }));
  }

  changeStep = (step) => {
    this.setState({ step });
  }

  goBack = () => {
    if (this.state.step === STEP.FORM)
      return this.changeStep(STEP.METHOD);
    this.props.history.replace('/profile-business');
  }

  selectMethod = async (method) => {
    if (!this.state.plan) return;

    //Si seleccionan paypal
    if (method === Constants.PLAN_PAYMENT_METHODS.PAYPAL) {
      //const currentOrigin = window.location.origin + "/#/profile-business/payment";
      //console.log('currentOrigin: ', currentOrigin)
      const basePath = window.location.pathname.includes('/Sysge24/web') ? '/Sysge24/web' : '';
      const currentOrigin = window.location.origin + basePath + "/#/profile-business/payment";
      console.log('currentOrigin: ', currentOrigin);
      try {
        let data = {
          user_id: this.state.form.user_id,
          plan_id: this.state.plan.id,
          description: this.state.plan.name,
          amount: this.state.plan.price,
          return_url: currentOrigin, // Enviar URL actual
          cancel_url: currentOrigin, // Enviar URL actual
        };

        const res = await axios.post("admin/plans/paypal/create-order", data);
        if (res.data.result) {
          // Agregar los datos de PayPal al mismo objeto `data`
          data.paypal_order_id = res.data.order_id;
          data.paypal_approve_url = res.data.approve_url;

          this.setState({
            paypal_order_id: res.data.order_id,
            paypal_approve_url: res.data.approve_url,
          });

          // Guardar en localStorage
          localStorage.setItem("paypal_data", JSON.stringify(data));
        }
      } catch (error) {
        Globals.showError(error?.message);
        console.log("admin/plans/paypal/create-order -> catch:", error);
      }
    }


    await this.change(method, 'payment_method');
    this.changeStep(STEP.FORM);
  }

  capturePaypalPayment = async (order_id) => {
    try {
      const paypalData = JSON.parse(localStorage.getItem("paypal_data"));
      paypalData.order_id = order_id;

      Globals.setLoading();
      const response = await axios.post('admin/plans/paypal/capture-payment', paypalData);
      console.log('response: ', response)

      if (response.status === 200 && response.data.status === 'success') {
        const payment = response.data.payment;
        console.log('payment: ', payment)
        if (payment.status === 'COMPLETED') {
          Globals.showSuccess('Pago realizado con éxito');
          await this.resetPaypal();
        } else {
          Globals.showError('El pago no se completó correctamente.');
          await this.resetPaypal();
        }
      } else {
        Globals.showError('Error al capturar el pago de PayPal.');
      }
    } catch (error) {
      console.log('error: ', error)
      Globals.showError(error.response?.data?.message || 'Ocurrió un error al capturar el pago.');
    } finally {
      Globals.quitLoading();
    }
  };

  resetPaypal = async () => {
    await this.setState({
      paypal_order_id: null,
      paypal_approve_url: null,
      paypal_payerId: null,
      paypal_token: null,
      paypal_processed: false, // Resetear la bandera
    });

    localStorage.removeItem("paypal_data");

    this.props.history.replace('/profile-business');
  };

  onClose = () => {
    this.props.history.replace('/profile-business');
  }

  render() {

    const { form, loading, plan, step } = this.state;
    const { user } = this.props;

    return (
      <Menu
        history={this.props.history}
        leftSlot={<BackButton onClick={this.goBack} />}
      >
        <main id="profile-business-payment">
          <nav>
            <div className="step">
              <div className="step-icon active">
                <img src={PaymentMethodIcon} alt="Step 1" />
              </div>
              <p>Método de pago</p>
            </div>

            <div className="divider">
              <div className={`line ${this.state.step === STEP.FORM ? 'active' : ''}`} />
              <p>void</p>
            </div>

            <div className="step">
              <div className={`step-icon ${this.state.step === STEP.FORM ? 'active' : ''}`}>
                <img src={PaymentDataIcon} alt="Step 2" />
              </div>
              <p>Datos de pago</p>
            </div>
          </nav>

          {step === STEP.METHOD && (
            <section className="method-step">
              <div className="summary">
                <div className="summary-icon">
                  <img src={PaymentSummaryIcon} alt="Método de pago" />
                </div>
                <div className="summary-details">
                  {loading ? (
                    <>
                      <div className="loading-text skeleton" style={{ width: '7rem' }} />
                      <div className="loading-text skeleton" style={{ width: '15rem' }} />
                    </>
                  ) : (
                    <>
                      <p>${Globals.formatMiles(form.amount)}</p>
                      <p>{plan?.name} ({plan?.payment?.year ? 'Anual' : 'Mensual'})</p>
                    </>
                  )}
                </div>
              </div>

              {
                this.state.payment_methods.map((item) => {
                  return (
                    <div className="method-option" onClick={() => this.selectMethod(item.id)}>
                      <div className="method-option-icon">
                        <img src={ ENV.BasePublic + 'img/' + item.icon } alt={ item.name } />
                      </div>
                      <div className="method-option-details">
                        <p>{ item.name }</p>
                      </div>
                    </div>
                  )
                })
              }
              

              {/*<div className="method-option" onClick={() => this.selectMethod(Constants.PLAN_PAYMENT_METHODS.PAYPAL)}>
                <div className="method-option-icon">
                  <img src={PaymentMobileIcon} alt="Paypal" />
                </div>
                <div className="method-option-details">
                  <p>Paypal</p>
                </div>
              </div>

              <div className="method-option" onClick={() => this.selectMethod(Constants.PLANS.PAYMENT_METHODS.SYSPAGO)}>
                <div className="method-option-icon">
                  <img src={PaymentMobileIcon} alt="Débito Inmediato" />
                </div>
                <div className="method-option-details">
                  <p>Débito Inmediato</p>
                </div>
              </div>*/}
            </section>
          )}

          {step === STEP.FORM && (
            <section className="form-step">
              {form.payment_method === Constants.PLANS.PAYMENT_METHODS.BNC_MOBILE && (
                <PlanPaymentMobile
                  onGoBack={this.goBack}
                  onClose={this.onClose}
                  plan={plan}
                  user={this.props.user}
                  amount={form.amount}
                  conversion={this.state.global_conversion.amount}
                  methodInfo={this.state.methodInfo}
                />
              )}

              {form.payment_method === Constants.PLAN_PAYMENT_METHODS.PAYPAL && (
                <PlanPaymentPaypal
                  onGoBack={this.goBack}
                  onClose={this.onClose}
                  plan={plan}
                  user={this.props.user}
                  amount={form.amount}
                  conversion={this.state.global_conversion.amount}
                  methodInfo={this.state.methodInfo}
                  paypal_approve_url={this.state.paypal_approve_url}
                />
              )}

              {form.payment_method === Constants.PLANS.PAYMENT_METHODS.SYSPAGO && (
                <PlanPaymentSyspago
                  onGoBack={this.goBack}
                  onClose={this.onClose}
                  plan={plan}
                  user={this.props.user}
                  amount={form.amount}
                  conversion={this.state.global_conversion.amount}
                  methodInfo={this.state.methodInfo}
                />
              )}
            </section>
          )}
        </main>
      </Menu>
    );
  }
}

const BackButton = ({ onClick }) => (
  <div className="back-button">
    <img src={LeftArrowIcon} onClick={() => onClick()} alt="Regresar" />
  </div>
)

export default connect(state => {
  return {
    user: state.user,
  };
})(PlanPayment);
