/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Input, Icon, Select, Modal, SelectActions } from '../../components';
import { Globals, Constants, axios } from '../../utils';
import TrashIcon from '../../assets/icons/trash.png';

function parse(value) {
	return parseFloat(parseFloat(value).toFixed(2));
}

class EditOrder extends React.Component {

	state = {
		form: {
			client_id: '',
			method_id: '',
			file: '',
			initial_payment: '',
			status: Constants.PAYMENT_TYPES.PAID,
			discount: '',
			credit_days: '',
			information: '',
			show_taxes: Constants.ORDERS.SHOW_TAXES.NO,
			iva: null,
			iva_bs: null,
			currentFileName: '',
		},
		total: 0,
		methods: [],
		client: null,
		visible: false,
		order: null,
		visible_product: false,
		visible_dates: false,
		dates: [],
		height: 0,
		methods_selected: [],
		disabled_button: false,
		visible_modal: false,
		seller: null,
		sellers: [],
		clients: [],
		warehouses: [],
		formatWarehouses: [],
		config: null,
	}

	async componentDidMount() {
		const order = this.props.order;

		let currentFileName = '';

		if (order?.file) {
			const splitted = String(order.file).split('.');
			currentFileName = `Comprobante.${splitted[splitted.length - 1]}`;
		}

		await this.setState({
			form: {
				...this.state.form,
				client_id: order.client_id || '',
				method_id: order.method_id || '',
				status: order.status_payment,
				initial_payment: order.initial_payment || '',
				discount: order.discount_percentage || '',
				credit_days: order.credit_days?.toString() || '',
				information: order.information || '',
				iva: order.iva,
				iva_bs: order.iva_bs,
				show_taxes: order.iva ? Constants.ORDERS.SHOW_TAXES.YES : Constants.ORDERS.SHOW_TAXES.NO,
				currentFileName,
			},
			seller: order.seller,
			order: {
				...order,
				details: order.details.filter(i => i.product && !i.product.deleted_at).map(i => {
					i.discount = i.discount_percentage;
					i.selected_price = i.price;
					i.original_quantity = i.quantity;
					i.warehouse_id = i.warehouse_id;
					return i;
				})
			},
			client: order.client,
			dates: order.paydays?.map(i => moment(i.date).format('DD-MM-YYYY')),
			methods_selected: order.payment_methods.map(i => {
				return {
					id: i.method_id,
					amount: i.amount.toString(),
					created_at: i.created_at
				}
			})
		});

		this.load();
		this.getConfig();
	}

	enableSubmit = () => {
		return this.setState({
			disabled_button: false
		});
	}

	load = async () => {
		this.getWarehouses();
	}

	getConfig = () => {
		axios
			.post("sync/config", { user_id: this.props.user.id })
			.then(({ data }) => {
				this.setState({ config: data.config });
			})
			.catch(() => Globals.showError())
			.finally(() => Globals.quitLoading());
	};

	getWarehouses = async () => {
		try {
			const data = {
				web: true,
				user_id: this.props.user.id,
				is_select: true,
				//Comentado para prod
				warehouse_id: this.props.user.level_id == Constants.ROLE_ADMIN ? null : this.props.user.warehouse_id,
				// warehouse_id: this.props.user.warehouse_principal,
			};

			const res = await axios.post('web/admin/warehouses', data);
			if (res.data?.warehouses) {
				const formatWarehouses = res.data.warehouses.map(Item => ({
					value: Item.id,
					label: Item.name
				}));

				const mainIdx = formatWarehouses.findIndex(x => x.value === this.props.user.warehouse_principal);
				if (mainIdx >= 0) {
					const main = formatWarehouses[mainIdx];
					formatWarehouses.splice(mainIdx, 1);
					formatWarehouses.unshift(main);
				}

				this.setState({
					warehouses: res.data.warehouses,
					formatWarehouses: formatWarehouses
				});
			}
		} catch (error) {
			Globals.showError();
			console.log('NewRequest -> getWarehouses -> catch: ', error);
		}
	}

	change = (value, target) => {
		this.setState({
			form: {
				...this.state.form,
				[target]: value
			}
		});
	}

	checkedChange = async (newValue) => {
		await this.setState(prevState => ({
			form: {
				...prevState.form,
				show_taxes: newValue == true ? Constants.ORDERS.SHOW_TAXES.YES : Constants.ORDERS.SHOW_TAXES.NO,
				iva: newValue == false ? null : prevState.form.iva,
				iva_bs: newValue == false ? null : prevState.form.iva_bs,
			}
		}));
		this.getTotal()
	}

	delete = (index) => {
		Globals.confirm('¿Desea eliminar el producto del pedido?', () => {
			let order = { ...this.state.order };
			order.details.splice(index, 1);
			this.setState({
				order
			});
		});
	}

	add = (index, item) => {
		if (item) {
			const product = item?.product;
			let stock = product?.stock || 0;

			let size_color = null;

			if (product?.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
				size_color = product?.size_colors?.find((i) => {
					if (item.size_id && item.color_id) {
						return i.size_id == item.size_id && i.color_id == item.color_id;
					}
					else if (item.size_id) {
						return i.size_id == item.size_id && i.color_id == null;
					}
					else if (item.color_id) {
						return i.color_id == item.color_id && i.size_id == null;
					}
				});
				stock = size_color?.stock || 0;
			}

			let order = { ...this.state.order };
			let qty = Number(order.details[index].quantity)
			qty = parseFloat(qty + 1).toFixed(2)

			// Si en config, el negative_stock es NO, evaluamos el stock
			if (this.state.config?.negative_stock == Constants.CONFIG.NO){
				if (Number(qty) > stock) {
					if (qty <= item.original_quantity) {
						order.details[index].quantity = qty;
						this.setState({
							order
						});
						return;
					}
					return;
				}
			}
			
			order.details[index].quantity = qty;
			this.setState({
				order
			});
		}
		else {
			let order = { ...this.state.order };
			order.details[index].quantity++;
			this.setState({
				order
			});
		}
	}

	remove = (index) => {
		let order = { ...this.state.order };
		if (order.details[index].quantity > 1) {
			let qty = order.details[index].quantity
			qty = parseFloat(qty - 1).toFixed(2)
			order.details[index].quantity = qty;
			this.setState({
				order
			});
		}
	}

	changeManual = (index, quantity) => {
		let order = { ...this.state.order };
		order.details[index].quantity = quantity;
		this.setState({
			order
		});
	}

	submit = async () => {
		if (this.state.order.details.filter(i => {
			return i.type == Constants.PRODUCTS_TYPE.SIZE_COLOR && !i.size_id && !i.color_id;
		}).length > 0) {
			Globals.showError("Por favor, ingrese el color/talla de los productos que lo requieran");
			this.enableSubmit();
			return false;
		}

		for (let index = 0; index < this.state.order.details.length; index++) {
			const i = this.state.order.details[index];
			const product = this.state.order.details.find(_i => _i.product_id == i.product_id);
			if (product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
				const item_find = this.state.order.details.find((item, _index) => {
					if (index != _index) {
						if (item.product_id == i.product_id && item.color_id == i.color_id && item.size_id == i.size_id) {
							return true;
						}
					}

					return false;
				});

				if (item_find) {
					const color = item_find.size_colors.find(_i => _i.color_id == i.color_id)?.color;
					const size = item_find.size_colors.find(_i => _i.size_id == i.size_id)?.size;
					const product = this.state.order.details.find(_i => _i.product_id == i.product_id);
					Globals.showError(`Lo sentimos, el producto ${product?.name} (${[size?.name, color?.name].filter(i => i != null).join(',')}) se encuentra más de una vez en la orden`);
					this.enableSubmit();
					return false;
				}
			}
		}

		for (let i = 0; i < this.state.order.details.length; i++) {
			const item = this.state.order.details[i];
			const product = this.state.order.details.find(_i => _i.product_id == item.product_id);
			if (product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
				const size_color = product?.size_colors?.find((i) => {
					if (item.size_id && item.color_id) {
						return i.size_id == item.size_id && i.color_id == item.color_id;
					}
					else if (item.size_id) {
						return i.size_id == item.size_id && i.color_id == null;
					}
					else if (item.color_id) {
						return i.color_id == item.color_id && i.size_id == null;
					}
				});
				if (!size_color) {
					const color = product.size_colors.find(_i => _i.color_id == item.color_id)?.color;
					const size = product.size_colors.find(_i => _i.size_id == item.size_id)?.size;
					Globals.showError(`Lo sentimos, el producto ${product?.name} (${[size?.name, color?.name].filter(i => i != null).join(',')}) no se encuentra en inventario`);
					this.enableSubmit();
					return false;
				}
			}
		}

		if (this.state.form.status == Constants.PAYMENT_TYPES.TO_PAY && this.state.form.credit_days <= 0) {
			Globals.showError("Debe ingresar los días de crédito");
			this.enableSubmit();
			return false;
		}

		if (this.state.order.details.filter(i => !i.quantity || i.quantity == 0).length > 0) {
			Globals.showError("Debe seleccionar la cantidad de todos los productos");
			this.enableSubmit();
			return false;
		}

		if (this.state.order.details.filter(i => !i.selected_price).length > 0) {
			Globals.showError("Debe seleccionar el precio de todos los productos");
			this.enableSubmit();
			return false;
		}

		if (this.state.form.status == Constants.PAYMENT_TYPES.TO_PAY) {
			const total = this.getTotal()
			const sumOfMethods = this.state.methods_selected.map(i => i.amount).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

			if (sumOfMethods > this.state.form.initial_payment) {
				Globals.showError("El pago inicial debe ser igual a la suma de los métodos de pago.");
				this.enableSubmit();
				return false;
			}

			if (this.state.form.initial_payment >= total) {
				Globals.showError("El pago inicial no puede ser igual o mayor al monto total. Este es un pedido por cobrar");
				this.enableSubmit();
				return false;
			}

			if (sumOfMethods >= total) {
				Globals.showError("La suma de los métodos de pago no puede ser igual o mayor al monto total. Este es un pedido por cobrar");
				this.enableSubmit();
				return false;
			}
		}

		if (this.state.form.discount != '' && this.state.form.discount > 100) {
			Globals.showError("El porcentaje de descuento general no puede ser mayor a 100%");
			this.enableSubmit();
			return false;
		}

		const detail = this.state.order.details.find((i) => {
			const product = this.state.order.details.find((_i) => _i.product_id == i.product_id);
			if (product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
				const size_color = product?.size_colors?.find((_i) => {
					if (i.size_id && i.color_id) {
						return _i.size_id == i.size_id && _i.color_id == i.color_id;
					}
					else if (i.size_id) {
						return _i.size_id == i.size_id && _i.color_id == null;
					}
					else if (i.color_id) {
						return _i.color_id == i.color_id && _i.size_id == null;
					}
				});

				return (parseInt(product.stock) + parseInt(i.original_quantity)) < i.quantity;
			}
			else {
				return (parseInt(product.stock) + parseInt(i.original_quantity)) < i.quantity;
			}
		});

		if (detail) {
			const product = this.state.order.details.find((i) => i.product_id == detail.product_id);
			const size_color = product?.size_colors?.find((_i) => {
				if (detail.size_id && detail.color_id) {
					return _i.size_id == detail.size_id && _i.color_id == detail.color_id;
				}
				else if (detail.size_id) {
					return _i.size_id == detail.size_id && _i.color_id == null;
				}
				else if (detail.color_id) {
					return _i.color_id == detail.color_id && _i.size_id == null;
				}
			});
			if (product) {
				if (product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
					const color = product.size_colors.find(_i => _i.id == detail.color_id)?.color;
					const size = product.size_colors.find(_i => _i.id == detail.size_id)?.size;
					Globals.showError("Lo sentimos, el producto " + product.name + ` (${[size?.name, color?.name].filter(i => i != null).join(',')}) no tiene suficiente stock (` + (parseInt(size_color?.stock) + parseInt(detail.original_quantity)) + ")");
				}
				else {
					Globals.showError("Lo sentimos, el producto " + product.name + " no tiene suficiente stock (" + (parseInt(product.stock) + parseInt(detail.original_quantity)) + ")");
				}
				this.enableSubmit();
				return false;
			}
		}

		if (this.state.form.status != Constants.PAYMENT_TYPES.TO_PAY || this.state.form.initial_payment != '') {
			if (this.state.methods_selected.length == 0) {
				Globals.showError("Debe seleccionar al menos 1 método de pago");
				this.enableSubmit();
				return false;
			}

			if (this.state.methods_selected.filter(i => !i.amount).length > 0) {
				Globals.showError("Debe ingresar el monto de cada método de pago");
				this.enableSubmit();
				return false;
			}

			// let total = this.getTotal();
			// let message = "La suma de los métodos de pago deben ser igual al total";

			// if (this.state.form.status == Constants.PAYMENT_TYPES.TO_PAY) {
			// 	total = this.state.form.initial_payment;
			// 	message = "La suma de los métodos de pago deben ser igual al pago inicial";
			// }

			// if (this.state.methods_selected.map(i => i.amount).reduce((a, b) => parseFloat(a) + parseFloat(b),0) != Globals.roundMiles(total)) {
			// 	Globals.showError(message);
			// 	this.enableSubmit();
			// 	return false;
			// }

			let total = this.getTotal();
			let message = "La suma de los métodos de pago deben ser igual al total";

			if (this.state.form.status == Constants.PAYMENT_TYPES.TO_PAY) {
				total = this.state.form.initial_payment;
				message = "La suma de los métodos de pago deben ser igual al pago inicial";
			}

			const tolerance = 0.0001; // Define la tolerancia para la comparación
			const sumOfMethods = this.state.methods_selected.map(i => i.amount).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
			const roundedTotal = Globals.roundMiles(total);

			if (Math.abs(sumOfMethods - roundedTotal) > tolerance) {
				Globals.showError(message);
				this.enableSubmit();
				return false;
			}
		}

		if (this.state.form.show_taxes == Constants.ORDERS.SHOW_TAXES.YES) {
			//Obtengo el iva y iva_bs
			const iva = this.getIva()
			const iva_bs = this.getIvaBs()
			this.setState({
				form: {
					...this.state.form,
					iva: iva,
					iva_bs: iva_bs,
				}
			});
		}



		Globals.setLoading();
		try {
			const res = await axios.upload('admin/orders/edit', {
				...this.state.form,
				seller_id: this.state.seller?.value || this.state.order?.seller_id || this.props.user.id,
				hasFile: true,
				details: JSON.stringify(this.state.order.details),
				currency: this.props.currency,
				order_id: this.state.order.id,
				user_id: this.props.user?.id,
				methods_selected: JSON.stringify(this.state.methods_selected),
				onError: () => {
					this.enableSubmit();
				}
			});

			//Mensaje de error
			if (res.data?.result == false) {
				Globals.showError(res.data?.error ?? 'El cliente ya ha alcanzado su limite de crédito permitido');
				Globals.quitLoading();
				return
			}

			Globals.showSuccess("Se ha modificado su pedido correctamente");
			this.props.onClose(true);

			// TODO: Habilitar el socket en el front para notificar el cambio
			// res.notifications?.forEach(item => {
			// 	Socket.emit(SocketEvents.STOCK.ALERT,{
			// 		message: item,
			// 		seller_id: this.state.order.seller_id
			// 	});
			// });

			// res.notifications_zero?.forEach(item => {
			// 	Socket.emit(SocketEvents.STOCK.ZERO,{
			// 		message: item,
			// 		seller_id: this.state.order.seller_id
			// 	});
			// });

		} catch (error) {
			this.enableSubmit();
			console.log(error);
			Globals.showError(error?.response?.data?.error || 'Ocurrió un error al editar el pedido');
		}

		Globals.quitLoading();
	}

	selectClient = () => {
		this.setState({
			visible: true
		});
	}

	onClose = (client = null) => {
		this.setState({
			visible: false
		});
		if (client) {
			this.setState({
				client,
				form: {
					...this.state.form,
					client_id: client.value
				}
			});
		}
	}

	getTotal = () => {
		let total = this.state.order?.details?.map((i) => {
			let total = i.selected_price * i.quantity;

			if (i.discount != null) {
				total = total - ((total * i.discount) / 100);
			}

			return total;
		}).reduce((a, b) => a + b, 0);

		if (this.state.form.discount != '') {
			total = total - ((total * this.state.form.discount) / 100);
		}

		if (this.state.form.show_taxes == Constants.ORDERS.SHOW_TAXES.YES) {
			console.log('getTotal>>>> showtaxesyes: ')
			const iva = this.getIva()
			total += Number(iva);
		}

		console.log('total: ', total)

		return total;
	}

	getProductIva = (product) => {
		const conversion = this.state.order?.conversion;
		let iva = 0;

		// Filtrar los detalles del pedido para obtener solo el producto deseado
		const filteredDetails = this.state.order?.details?.filter(detail => detail.product.id === product.id);

		filteredDetails?.forEach(i => {
			let product_iva = 0;
			const quantity = i?.decimal_stock == Constants.DECIMAL_STOCK.NO ? parseInt(i.quantity) : Number(i.quantity);
			const subtotalWithoutDiscount = quantity * i.selected_price;
			const prodDiscountPercentage = i.discount_percentage || 0;
			const genDiscountPercentage = this.state.form.discount || 0;

			const prodDiscountAmount = prodDiscountPercentage > 0
				? parse((subtotalWithoutDiscount * prodDiscountPercentage) / 100)
				: 0;

			const genDiscountAmount = genDiscountPercentage > 0
				? parse(((subtotalWithoutDiscount - prodDiscountAmount) * genDiscountPercentage) / 100)
				: 0;

			if (!!this.state.form.show_taxes && (i.product.exento == Constants.PRODUCT_EXENTO.NO)) {
				const subtotalWithDiscounts = parse(subtotalWithoutDiscount - prodDiscountAmount - genDiscountAmount);
				product_iva = parse((subtotalWithDiscounts * Constants.TAXES.IVA) / 100);
			}

			iva += product_iva;
		});

		return iva;
	}

	getIva = () => {
		const conversion = this.state.order?.conversion
		let iva = 0
		let iva_bs = 0

		this.state.order?.details?.map((i) => {
			let product_iva = 0
			const quantity = i?.decimal_stock == Constants.DECIMAL_STOCK.NO ? parseInt(i.quantity) : Number(i.quantity);
			const subtotalWithoutDiscount = parse(quantity * i.selected_price);
			const prodDiscountPercentage = i.discount || 0;
			const genDiscountPercentage = this.state.form.discount || 0;

			const prodDiscountAmount = prodDiscountPercentage > 0
				? parse((subtotalWithoutDiscount * prodDiscountPercentage) / 100)
				: 0;

			const genDiscountAmount = genDiscountPercentage > 0
				? parse(((subtotalWithoutDiscount - prodDiscountAmount) * genDiscountPercentage) / 100)
				: 0;

			if (!!this.state.form.show_taxes && (i.product.exento == Constants.PRODUCT_EXENTO.NO)) {
				const subtotalWithDiscounts = parse(subtotalWithoutDiscount - prodDiscountAmount - genDiscountAmount);
				product_iva = parse((subtotalWithDiscounts * Constants.TAXES.IVA) / 100);
			}

			iva += product_iva
			iva_bs += parse(product_iva * conversion)
		});

		console.log('iva: ', iva)
		console.log('iva_bs: ', iva_bs)

		return iva
	}

	getIvaBs = () => {
		const conversion = this.state.order?.conversion
		let iva_bs = 0

		this.state.order?.details?.map((i) => {
			let product_iva = 0
			const quantity = i?.decimal_stock == Constants.DECIMAL_STOCK.NO ? parseInt(i.quantity) : Number(i.quantity);
			const subtotalWithoutDiscount = parse(quantity * i.selected_price);
			const prodDiscountPercentage = i.discount_percentage || 0;
			const genDiscountPercentage = this.state.form.discount || 0;

			const prodDiscountAmount = prodDiscountPercentage > 0
				? parse((subtotalWithoutDiscount * prodDiscountPercentage) / 100)
				: 0;

			const genDiscountAmount = genDiscountPercentage > 0
				? parse(((subtotalWithoutDiscount - prodDiscountAmount) * genDiscountPercentage) / 100)
				: 0;

			if (!!this.state.form.show_taxes && (i.product.exento == Constants.PRODUCT_EXENTO.NO)) {
				const subtotalWithDiscounts = parse(subtotalWithoutDiscount - prodDiscountAmount - genDiscountAmount);
				product_iva = parse((subtotalWithDiscounts * Constants.TAXES.IVA) / 100);
			}

			iva_bs += (product_iva * conversion)
		});

		console.log('iva_bs: ', iva_bs)

		return iva_bs
	}

	getDiscount = () => {
		let total = this.state.order?.details?.map((i) => {
			let total = i.quantity * i.selected_price;

			if (i.discount != null) {
				total = total - ((total * i.discount) / 100);
			}

			return total;
		}).reduce((a, b) => a + b, 0);

		let total_discount = this.state.order?.details?.map((i) => {
			let total = i.quantity * i.selected_price;

			return ((total * i.discount) / 100);
		}).reduce((a, b) => a + b, 0);

		if (this.state.form.discount != '') {
			total_discount = total_discount + ((total * this.state.form.discount) / 100);
		}

		return total_discount;
	}

	getSubtotal = () => {
		let total = this.state.order?.details.map((i) => {
			return i.quantity * i.selected_price;
		}).reduce((a, b) => a + b, 0);

		return total;
	}

	addProduct = () => {
		document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
		this.setState({ visible_product: true });
	}

	onCloseProduct = (_products = null) => {
		document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
		this.setState({
			visible_product: false
		});
		if (_products) {
			let order = { ...this.state.order };
			_products.forEach(item => {
				order.details.push({
					product: item,
					quantity: 1,
					product_id: item.id,
					selected_price: item.sale_price,
					price: item.sale_price,
					warehouse_id: item.warehouse_id,
				});
			});
			this.setState({
				order
			});
		}
	}

	setPaymentType = (status) => {
		this.setState({
			form: {
				...this.state.form,
				status
			}
		});
	}

	openDates = () => {
		this.setState({
			visible_dates: true
		});
	}

	onCloseDates = (dates = null) => {
		this.setState({
			visible_dates: false,
			dates: dates || this.state.dates
		});
	}

	changeValue = (value, target, index, callback = () => { }) => {
		this.setState(state => {
			const details = [...state.order.details];
			details[index][target] = value;
			return {
				order: {
					...state.order,
					details
				}
			}
		}, () => callback());
	}

	onCloseSeller = (seller = null) => {
		this.setState({
			visible_modal: false,
			seller: seller || this.state.seller
		});
	}

	selectSeller = () => {
		this.setState({
			visible_modal: true
		});
	}

	render() {
		const { visible, order, visible_product, dates, visible_dates, visible_modal } = this.state;
		const can_include_taxes = Globals.canIncludeTaxes(this.props.user)

		return (
			<id id="modal-edit-order">
				{visible && (
					<Modal
						withoutClose={true}
						onClose={() => this.onClose()}>
						{/* <SelectClient
							onClose={ this.onClose }
						/> */}
					</Modal>
				)}
				{visible_modal && (
					<Modal
						withoutClose={true}
						onClose={() => this.onCloseSeller()}>
						{/* <SelectSeller
							onClose={ this.onCloseSeller }
						/> */}
					</Modal>
				)}
				{visible_dates && (
					<Modal
						withoutClose={true}
						onClose={() => this.onCloseDates()}>
						{/* <SelectDates
							dates={ this.state.dates }
							onClose={ this.onCloseDates }
						/> */}
					</Modal>
				)}

				<Modal
					title="Seleccionar producto"
					onClose={() => this.onCloseProduct()}
					visible={visible_product}
				>
					<SelectProduct
						onSelect={this.onCloseProduct}
						onClose={() => this.onCloseProduct()}
						warehouses={this.state.warehouses}
						formatWarehouses={this.state.formatWarehouses}
					/>
				</Modal>

				{visible_product && (
					<div
						className="backdrop"
						onClick={() => this.close()}
					/>
				)}

				<section style={{ flex: 3 }}>
					<div className="flex-end">
						<div className="" style={{ width: '60%' }}>
							<div className="totals">
								<div className="row-between">
									<div>Subtotal:</div>
									<div>$ {Globals.formatMiles(this.getSubtotal())}</div>
								</div>
								<div className="row-between">
									<div>Descuento:</div>
									<div>$ {Globals.formatMiles(this.getDiscount())}</div>
								</div>
								{this.state.form.show_taxes === Constants.QUOTES.SHOW_TAXES.YES && (
									<div className="row-between">
										<div>Iva:</div>
										<div>$ {Globals.formatMiles(this.getIva())}</div>
									</div>
								)}
								<div className="row-between">
									<div>Total:</div>
									<div>$ {Globals.formatMiles(this.getTotal())}</div>
								</div>
							</div>

							{can_include_taxes && (
								<div className="export-option" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '8rem' }}>
									<input
										name="show_taxes"
										id="exento-check"
										type="checkbox"
										checked={this.state.form.show_taxes === Constants.ORDERS.SHOW_TAXES.YES}
										style={{ marginRight: '0.5rem' }}
										onChange={() => this.checkedChange(!this.state.form.show_taxes)}
									/>
									<label className="" htmlFor="exento-check" style={{ fontSize: 20, marginBottom: 0 }}>
										Incluir Impuesto
									</label>
								</div>
							)}

							<div className="tab-container">
								<div
									className={`tab ${this.state.form.status === Constants.PAYMENT_TYPES.PAID ? 'selected' : ''}`}
									onClick={() => this.setPaymentType(Constants.PAYMENT_TYPES.PAID)}
								>
									Pagado
								</div>
								<div
									className={`tab ${this.state.form.status === Constants.PAYMENT_TYPES.TO_PAY ? 'selected' : ''}`}
									onClick={() => this.setPaymentType(Constants.PAYMENT_TYPES.TO_PAY)}
								>
									Por Cobrar
								</div>
							</div>
						</div>
					</div>

					<div className="divider" />

					<div className="flex-end">
						<Button className="primary" onClick={() => this.addProduct()}>
							<span>Agregar producto</span>
						</Button>
					</div>

					<div className="container-products">
						{(order && !order.details.length) && (
							<div className="no-items">No hay productos en el pedido</div>
						)}
						{
							order && order.details.map((item, index) => {
								const product = item.product;
								let stock = product?.stock || 0;
								let label_total = '(' + item.quantity + ' x ' + Globals.formatMiles(item.selected_price, true) + ')';
								let total = item.selected_price * item.quantity;

								if (item.discount != null && item.discount != '') {
									total = total - ((total * item.discount) / 100);
									label_total += ' - ' + Globals.formatMiles(item.discount, true, '') + '%';
								}

								if (this.state.form.discount != null && this.state.form.discount != '') {
									total = total - ((total * this.state.form.discount) / 100);
								}

								if (!!this.state.form.show_taxes && !product.exento) {
									label_total += ' + ' + Globals.formatMiles(this.getProductIva(product), true) + ' IVA';
									total += this.getProductIva(product)
								}

								let size_color = null;
								let prices = product.prices;

								if (product?.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
									size_color = product?.size_colors?.find((i) => {
										if (item.size_id && item.color_id) {
											return i.size_id == item.size_id && i.color_id == item.color_id;
										}
										else if (item.size_id) {
											return i.size_id == item.size_id && i.color_id == null;
										}
										else if (item.color_id) {
											return i.color_id == item.color_id && i.size_id == null;
										}
									});

									prices = size_color?.prices || [];
									stock = size_color?.stock || 0;
								}

								if (product)

									return (
										<div className="product" key={index}>
											<div className="product-image">
												<img src={Globals.fromPhotos(product?.photo?.file)} alt="Foto del producto" />
											</div>
											<div className="product-details">
												<div className="product-name">{product?.name}</div>
												<div className="row">
													<div className="flex">
														<Select
															label="Precio"
															labelClass="material"
															className="material"
															onChange={e => {
																this.changeValue(e.target.value, 'selected_price', index);
															}}
															options={[
																{ value: size_color?.sale_price || item.price, label: Globals.formatMiles(size_color?.sale_price || item.price) },
																...prices.map(i => {
																	return {
																		value: i.price,
																		label: Globals.formatMiles(i.price)
																	}
																})
															]}
															value={item.selected_price}
															placeholder="Precio"
														/>
													</div>

													<div className="flex">
														<Input
															label="Descuento (%)"
															type="number"
															placeholder="Opcional"
															labelClass="material"
															className="material"
															value={item.discount?.toString() || ''}
															onChange={e => {
																if (Globals.validateDouble(e.target.value) || e.target.value == '') {
																	this.changeValue(e.target.value, 'discount', index);
																}
															}}
														/>
														{item.discount != null && item.discount > 100 && (
															<div className="error-discount">El descuento no puede ser mayor a 100%</div>
														)}
													</div>

													<div className="qty-actions">
														<div className="details">{Globals.formatMiles(total)} {label_total}</div>
														<div className="qty">
															<button
																type="button"
																className="qty-remove"
																onClick={() => this.remove(index, product)}
															>
																<Icon name="minus" />
															</button>
															<input
																className="qty-input"
																type="number"
																//value={ item.quantity?.toString() || '' }
																value={
																	product.decimal_stock == Constants.DECIMAL_STOCK.NO
																		? parseInt(item.quantity || 0)
																		: item.quantity || 0
																}
																onChange={e => {
																	if (product?.decimal_stock == Constants.DECIMAL_STOCK.NO) {
																		if (Globals.validateInteger(e.target.value) || e.target.value == '') {
																			// Si en config, el negative_stock es NO, evaluamos el stock
																			if (this.state.config?.negative_stock == Constants.CONFIG.NO) {
																				if (e.target.value > stock) {
																					return;
																				}
																			}																
																			this.changeManual(index, e.target.value);
																		}
																	}
																	else {
																		// Si en config, el negative_stock es NO, evaluamos el stock
																		if (this.state.config?.negative_stock == Constants.CONFIG.NO){
																			if (e.target.value > stock) {
																				return;
																			}
																		}													
																		this.changeManual(index, e.target.value);
																	}
																}}
																max={stock}
															/>
															<button
																type="button"
																className="qty-add"
																onClick={() => this.add(index, item)}
															>
																<Icon name="plus" />
															</button>
														</div>
													</div>

													<div className="product-actions">
														<button onClick={() => this.delete(index)} title="Eliminar producto">
															<img src={TrashIcon} style={{ width: 20, height: 20 }} alt="Eliminar" />
														</button>
													</div>
												</div>

												{product?.type == Constants.PRODUCTS_TYPE.SIZE_COLOR && (
													<div className="size-colors-form">
														{
															[...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].length > 0 && (
																<div className="colors">
																	<div className="label">Color:</div>
																	<div className="row">
																		{
																			[...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].map((_item) => {
																				const isSelected = item.color_id == _item;
																				return (
																					<div
																						className={`circle ${isSelected ? 'selected' : ''}`}
																						style={{ backgroundColor: product?.size_colors?.find(i => i.color_id == _item)?.color?.hex }}
																						onClick={() => {
																							this.changeValue(_item, 'color_id', index, () => {
																								const size_color = product?.size_colors?.find((i) => {
																									if (item.size_id && item.color_id) {
																										return i.size_id == item.size_id && i.color_id == item.color_id;
																									}
																									else if (item.size_id) {
																										return i.size_id == item.size_id && i.color_id == null;
																									}
																									else if (item.color_id) {
																										return i.color_id == item.color_id && i.size_id == null;
																									}
																								});
																								if (size_color)
																									this.changeValue(size_color?.sale_price, 'price', index, () => {
																										this.changeValue(size_color?.sale_price, 'selected_price', index);
																									});
																							});
																						}}
																					/>
																				)
																			})
																		}
																	</div>
																</div>
															)
														}
														{
															[...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))].length > 0 && (
																<div className="sizes">
																	<div className="label">Talla</div>
																	<Select
																		labelClass="material"
																		className="material"
																		disabledFirst
																		onChange={e => {
																			this.changeValue(e.target.value, 'size_id', index, () => {
																				const size_color = product?.size_colors?.find((i) => {
																					if (item.size_id && item.color_id) {
																						return i.size_id == item.size_id && i.color_id == item.color_id;
																					}
																					else if (item.size_id) {
																						return i.size_id == item.size_id && i.color_id == null;
																					}
																					else if (item.color_id) {
																						return i.color_id == item.color_id && i.size_id == null;
																					}
																				});
																				if (size_color)
																					this.changeValue(size_color?.sale_price, 'price', index, () => {
																						this.changeValue(size_color?.sale_price, 'selected_price', index);
																					});
																			});
																		}}
																		options={[...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))].map(_item => {
																			const size = product.size_colors.find(i => i.size_id == _item);

																			return {
																				value: size?.size?.id,
																				label: size?.size?.name,
																			}
																		})}
																		value={item.size_id}
																	/>
																</div>
															)
														}
													</div>
												)}
											</div>
										</div>
									)
								else
									return null;
							})
						}
					</div>
				</section>

				<section style={{ flex: 2 }}>
					{
						order && order.details.length > 0 && (
							<div className="container-form">
								<div className="container-buttons">
									<button
										onClick={async () => {
											await this.setState({ disabled_button: true });
											this.submit();
										}}
										disabled={this.state.disabled_button}
									>
										<span>Finalizar Compra</span>
									</button>

									{/* <button className="danger" onClick={ () => this.props.onClose() }>
										<span>Borrar compra</span>
									</button> */}
								</div>

								<div className="divider" />

								<div className="input-container">
									<div className="label">Vendedor (Opcional)</div>
									<Select
										labelClass="material"
										className="material"
										onChange={e => {
											if (!e.target.value || isNaN(+e.target.value)) return;
											const seller = this.props.sellers.find(x => +x.value === +e.target.value);
											this.onCloseSeller(seller);
										}}
										value={this.state.seller?.id}
										options={this.props.sellers}
										placeholder="Seleccionar"
									/>
								</div>

								{/* <div className="input-container" onClick={ this.selectSeller }>
									<div className="label">Vendedor (Opcional)</div>
									<div style={ styles.inputFile }>
										<div>{ this.state.seller?.name || '' }</div>
									</div>
								</div> */}

								<div className="input-container">
									<div className="label">Cliente</div>
									<Select
										labelClass="material"
										className="material"
										onChange={e => {
											if (!e.target.value || isNaN(+e.target.value)) return;
											const client = this.props.clients.find(x => +x.value === +e.target.value);
											this.onClose(client);
										}}
										value={this.state.form.client_id}
										options={this.props.clients}
										placeholder="Seleccionar"
									/>
								</div>

								{/* <div className="input-container" onClick={ this.selectClient }>
									<div className="label">Cliente</div>
									<div style={ styles.inputFile }>
										<div>{ this.state.client?.name || '' }</div>
									</div>
								</div> */}

								{
									this.state.form.status == Constants.PAYMENT_TYPES.TO_PAY && (
										<div style={{ marginBottom: 10 }}>
											<div className="input-container">
												<Input
													type="number"
													label="Pago Inicial (Opcional)"
													labelClass="material"
													className="material"
													value={this.state.form.initial_payment.toString()}
													onChange={e => {
														if (Globals.validateDouble(e.target.value) || e.target.value == '') {
															this.change(e.target.value, 'initial_payment');
														}
													}}
												/>
											</div>

											<div className="input-container">
												{/* <Select
													onValueChange={ value => {
														this.setState({
															form: {
																...this.state.form,
																credit_days: value
															}
														});
													} }
													items={ Constants.DAYS_PAY }
													value={ this.state.form.credit_days }
													placeholder="Seleccionar"
												/> */}
												<Input
													label="Días de Crédito"
													type="number"
													labelClass="material"
													className="material"
													value={this.state.form.credit_days}
													onChange={e => {
														if (Globals.validateInteger(e.target.value) || e.target.value == '') {
															this.change(e.target.value, 'credit_days');
														}
													}}
												/>
											</div>
										</div>
									)
								}

								<div className="input-container">
									<div className="label">Agregar Método de Pago</div>
									<Select
										labelClass="material"
										className="material"
										onChange={e => {
											if (e.target.value) {
												let methods_selected = [...this.state.methods_selected];
												methods_selected.push({
													id: e.target.value
												});
												this.setState({
													methods_selected,
													form: {
														...this.state.form,
														method_id: ''
													}
												});
											}
										}}
										value={this.state.form.method_id}
										options={this.props.methods.filter(i => this.state.methods_selected.map(i => i.id).indexOf(i.id) == -1).map(i => {
											return {
												value: i.value,
												label: i.label
											}
										})}
										placeholder="Seleccionar"
									/>
								</div>

								{
									this.state.methods_selected.length > 0 && (
										<div className="container-payments">
											{
												this.state.methods_selected.map((i, index) => {
													const method = this.props.methods.find(item => item.value == i.id);
													console.log('i: ', i)

													return (
														<div className="payment-method">
															<div className="payment-method-desc">
																{method?.label}
															</div>
															<div className="payment-method-desc">
																<Input
																	type="number"
																	labelClass="material"
																	className="material"
																	placeholder="Monto en $"
																	value={i.amount}
																	onChange={e => {
																		if (Globals.validateDouble(e.target.value) || e.target.value == '') {
																			let methods_selected = [...this.state.methods_selected];
																			methods_selected[index].amount = e.target.value;
																			this.setState({
																				methods_selected
																			});
																		}
																	}}
																/>
																{/* {moment(i.created_at).format('DD/MM/YYYY HH:mm')} */}
															</div>
															<div className="payment-method-delete">
																<button
																	onClick={() => {
																		let methods_selected = [...this.state.methods_selected];
																		methods_selected.splice(index, 1);
																		this.setState({ methods_selected });
																	}}
																	title="Eliminar pago"
																>
																	<img src={TrashIcon} style={{ width: 20, height: 20 }} alt="Eliminar" />
																</button>
															</div>
														</div>
													)
												})
											}
										</div>
									)
								}

								<div className="row-between">
									<div className="input-container">
										<Input
											label="Descuento General (%)"
											type="number"
											labelClass="material"
											className="material"
											placeholder="Opcional"
											value={this.state.form.discount?.toString() || ''}
											onChange={e => {
												if (Globals.validateDouble(e.target.value) || e.target.value == '') {
													this.change(e.target.value, 'discount');
												}
											}}
										/>
									</div>

									{(this.state.form.discount != null && this.state.form.discount > 100) && (
										<div className="error-discount">El descuento no puede ser mayor a 100%</div>
									)}

									<div className="input-container">
										<Input
											label="Comprobante de Pago"
											labelClass="material"
											className="material"
											placeholder="Opcional"
											value={this.state.form.file?.name || this.state.form.currentFileName}
											onChange={() => { }}
											onClick={() => document.getElementById('upload-voucher').click()}
											readOnly
										/>
										<input
											id="upload-voucher"
											type="file"
											style={{ display: 'none' }}
											onChange={e => this.change(e.target.files[0], 'file')}
										/>
									</div>
								</div>

								<div className="input-container">
									<Input
										label="Información adicional"
										labelClass="material"
										className="material"
										// inputStyle={ { height: Math.max(60, this.state.height) } }
										// onContentSizeChange={(event) => {
										// 	this.setState({ height: event.nativeEvent.contentSize.height })
										// }}
										multiline={true}
										value={this.state.form.information}
										placeholder="Agregar información adicional (Opcional)"
										onChange={e => this.change(e.target.value, 'information')}
									/>
								</div>
							</div>
						)
					}
				</section>
			</id>
		)
	}
}

const SizeColorForm = ({ product, onChange }) => {

	const [colors, setColors] = useState([]);
	const [sizes, setSizes] = useState([]);

	function getUniqueItems() {
		const _colors = product.size_colors.filter(i => i.color_id).reduce((prev, curr) => prev.set(curr.color_id, curr.color), new Map());
		const _sizes = product.size_colors.filter(i => i.size_id).reduce((prev, curr) => prev.set(curr.size_id, curr.size), new Map());

		setColors([..._colors.values()]);
		setSizes([..._sizes.values()]);
	}

	useEffect(() => {
		getUniqueItems();
	}, []);

	return (
		<div className="size-colors-form">
			{!!sizes.length && (
				<div className="sizes">
					<Select
						label="Talla"
						labelClass="material"
						className="material"
						disabledFirst
						options={sizes.map(item => ({ value: item.id, label: item.name }))}
						value={product.size_id}
						onChange={e => onChange(Number(e.target.value), 'size_id')}
					/>
				</div>
			)}

			{!!colors.length && (
				<div className="colors">
					<SelectActions
						label="Color"
						options={colors.map(item => ({
							value: item.id,
							label: (<div className="circle" style={{ backgroundColor: item.hex }} />),
							actions: [],
						}))}
						value={product.color_id}
						onChange={id => onChange(Number(id), 'color_id')}
					/>
				</div>
			)}
		</div>
	);
}

const SelectProduct = ({ onSelect, onClose, warehouses, formatWarehouses }) => {
	const user = useSelector(state => state.user);
	const mainWarehouse = user.warehouse_id || user.warehouse_principal;
	const [products, setProducts] = useState([]);
	const [selected, setSelected] = useState([]);
	const [form, setForm] = useState({ warehouse_id: mainWarehouse, search: '' });

	//filtro los productos, solo los que tengan stock > 0
	const products_filter = products.map(product => {
		let totalStock = product.stock;

		if (product.size_colors) {
			// Filtramos los colores que tienen stock > 0
			product.size_colors = product.size_colors.filter(color => color.stock > 0);
			totalStock += product.size_colors.reduce((total, color) => total + color.stock, 0);
		}

		let matchSearch = true;
		if (form.search) {
			const search = form.search.toLowerCase();
			matchSearch = product?.name?.toLowerCase()?.includes(search) || product?.code?.toLowerCase()?.includes(search);
		}

		if (totalStock > 0 && matchSearch) {
			return product;
		} else {
			return null;
		}
	}).filter(Boolean); // Filtramos los productos null

	const select = (item) => {
		if (selected.includes(item.id)) {
			const others = selected.filter(x => x !== item.id);
			setSelected(others);
		} else {
			setSelected([...selected, item.id]);
		}
	}

	const onSelectWarehouse = async (warehouse_id) => {
		setForm({ ...form, warehouse_id: warehouse_id });

		const selectedWarehouse = warehouses.find(warehouse => warehouse.id == warehouse_id);
		if (selectedWarehouse) {
			const products = selectedWarehouse.products.map(i => {
				const total_quantity = i.total_quantity;
				if (total_quantity <= 0) return null;
				return {
					...i.product,
					total_quantity: total_quantity
				};
			}).filter(product => product !== null);
			setProducts(products);
		}
	}

	const submit = () => {
		onSelect(products_filter.filter(x => selected.includes(x.id)).map(x => ({ ...x, warehouse_id: form.warehouse_id })));
		onClose();
	}

	useEffect(() => {
		onSelectWarehouse(mainWarehouse);
	}, []);

	const canSelectWarehouse = user.level_id == Constants.ROLE_ADMIN || (user.level_id == Constants.ROLE_MODERATOR && user.warehouse_id === null);

	return (
		<div id="container-create-edit-quote-select-product">
			{canSelectWarehouse && (
				<Select
					label="Almacén"
					className="material"
					labelClass="material"
					value={form.warehouse_id}
					options={formatWarehouses}
					onChange={e => onSelectWarehouse(parseInt(e.target.value))}
				/>
			)}

			<Input
				color=" "
				placeholder="Buscar por nombre"
				className="material"
				labelClass="material"
				onChange={e => setForm({ ...form, search: e.target.value })}
			/>

			{!!selected.length && (
				<div className="product-totals">
					<span>Seleccionados: {selected.length}</span>
					<button
						type="button"
						className="done"
						title="Agregar a la cotización"
						onClick={() => submit()}
					>
						<Icon name="check" />
					</button>
				</div>
			)}

			{products_filter.map((item, index) => {
				const isSelected = selected.includes(item.id);
				return (
					<div
						key={`product-item-${index}`}
						className={`product-item ${isSelected ? 'selected' : ''}`}
						onClick={() => select(item)}
					>
						<div className="product-image">
							<img src={Globals.fromPhotos(item?.photo?.file)} alt="Producto" />
						</div>
						<div className="product-details">
							<span className="product-name" title={item?.name}>{item?.name}</span>
						</div>
						<div>${item?.sale_price}</div>
					</div>
				)
			})}
		</div>
	);
}

export default connect(state => {
	return {
		user: state.user,
		currency: state.currency,
	}
})(EditOrder);